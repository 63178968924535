// src/components/layout/mobile/MobileSidebar.js
import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Badge,
} from '@mui/material';
import {
  Dashboard,
  Power,
  ExpandLess,
  ExpandMore,
  InsertDriveFile,
  Message,
  Notifications,
  ExitToApp,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../../context/AuthContext';

function MobileSidebar({ open, onClose }) {
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState({});

  const handleToggle = (menu) => {
    setMenuOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  const onLogout = () => {
    logoutUser();
    navigate('/'); // Redirect to login page after logout
    onClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <List sx={{ width: 250 }}>
        <ListItem button onClick={() => handleToggle('dashOver')}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard Overview" />
          {menuOpen.dashOver ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.dashOver} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/dashboard');
                onClose();
              }}
            >
              <ListItemText primary="All Buildings" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/dashboard/building-a');
                onClose();
              }}
            >
              <ListItemText primary="Building A" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/dashboard/building-b');
                onClose();
              }}
            >
              <ListItemText primary="Building B" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={() => {
            navigate('/documents');
            onClose();
          }}
        >
          <ListItemIcon>
            <InsertDriveFile />
          </ListItemIcon>
          <ListItemText primary="Documents" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            navigate('/messages');
            onClose();
          }}
        >
          <ListItemIcon>
            <Message />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </ListItem>

        {/* Energy Production Menu */}
        <ListItem button onClick={() => handleToggle('energyProduction')}>
          <ListItemIcon>
            <Power />
          </ListItemIcon>
          <ListItemText primary="Energy Production" />
          {menuOpen.energyProduction ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.energyProduction} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/energy-production/real-time');
                onClose();
              }}
            >
              <ListItemText primary="Real-Time Production" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/energy-production/historical-data');
                onClose();
              }}
            >
              <ListItemText primary="Historical Data" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/energy-production/forecasts');
                onClose();
              }}
            >
              <ListItemText primary="Forecasts" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => {
                navigate('/notifications');
                onClose();
              }}>
            <ListItemIcon>
                <Badge badgeContent={4} color="secondary">
                    <Notifications />
                </Badge>
            </ListItemIcon>
            <ListItemText primary="Notifications" />
        </ListItem>

        {/* Logout */}
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MobileSidebar;
