// src/components/forms/OnboardSchoolForm.js
import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Grid,
} from '@mui/material';

/**
 * Props:
 *  - onSubmit(formData): callback to handle form submission
 */
function OnboardSchoolForm({ onSubmit }) {
  const [formData, setFormData] = useState({
    districtName: '',
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phone: '',
    city: '',
    county: '',
    primaryUtility: '',
    numberOfSchools: 0,
    numberOfStudents: 0,
    estimatedSqFt: 0,
    // optional fields if you want them in this form:
    totalAnnualKwh: 0,
    averageAnnualKw: 0,
    annualGasTherms: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // If numeric field, convert to Number
    const numericFields = [
      'numberOfSchools',
      'numberOfStudents',
      'estimatedSqFt',
      'totalAnnualKwh',
      'averageAnnualKw',
      'annualGasTherms',
    ];
    setFormData((prev) => ({
      ...prev,
      [name]: numericFields.includes(name) ? Number(value) : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass formData to parent callback
    onSubmit(formData);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          School Onboarding
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="districtName"
                label="School District Name"
                fullWidth
                value={formData.districtName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="title"
                label="Title (Mr, Mrs, Dr, etc.)"
                fullWidth
                value={formData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstName"
                label="First Name"
                fullWidth
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="lastName"
                label="Last Name"
                fullWidth
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                type="email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="phone"
                label="Phone"
                fullWidth
                value={formData.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="city"
                label="City"
                fullWidth
                value={formData.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="county"
                label="County"
                fullWidth
                value={formData.county}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="primaryUtility"
                label="Primary Utility"
                fullWidth
                value={formData.primaryUtility}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="numberOfSchools"
                label="# of Schools"
                type="number"
                fullWidth
                value={formData.numberOfSchools}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="numberOfStudents"
                label="# of Students"
                type="number"
                fullWidth
                value={formData.numberOfStudents}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="estimatedSqFt"
                label="Estimated SqFt"
                type="number"
                fullWidth
                value={formData.estimatedSqFt}
                onChange={handleChange}
              />
            </Grid>

            {/* Optional advanced fields for Demand Services, if you want them in this form */}
            <Grid item xs={12} md={4}>
              <TextField
                name="totalAnnualKwh"
                label="Total Annual kWh"
                type="number"
                fullWidth
                value={formData.totalAnnualKwh}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="averageAnnualKw"
                label="Average Annual kW"
                type="number"
                fullWidth
                value={formData.averageAnnualKw}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="annualGasTherms"
                label="Annual Gas Therms"
                type="number"
                fullWidth
                value={formData.annualGasTherms}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Button type="submit" variant="contained">
              Submit Onboarding
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default OnboardSchoolForm;
