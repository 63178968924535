// src/components/messaging/ConversationsList.js
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Stack,
  Avatar,
  Divider,
} from '@mui/material';
import { Chat as ChatIcon, Send as SendIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

function ConversationsList() {
  const { authState } = useContext(AuthContext);
  const { token, user } = authState;
  const [conversations, setConversations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/api/messages/conversations', {
      headers: { 'x-auth-token': token },
    })
      .then((res) => res.json())
      .then((data) => setConversations(data.conversations || []))
      .catch((err) => console.error(err));
  }, [token]);

  // Optional: Start new conversation flow
  // If you have a page to start a new conversation, 
  // you can navigate there with a button
  const handleNewConversation = () => {
    navigate('/messages/new'); 
    // or some route where user chooses who to message
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography variant="h4" component="h1">
          Conversations
        </Typography>
        {/*<Button
          variant="contained"
          startIcon={<SendIcon />}
          onClick={handleNewConversation}
        >
          Start New Conversation
        </Button>*/}
      </Box>

      {conversations.length === 0 ? (
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          <ChatIcon sx={{ fontSize: 64, mb: 1 }} />
          <Typography variant="h6" gutterBottom>
            No Conversations Yet
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', maxWidth: 400 }}>
            You haven’t started any chats or received any messages.
            Click “Start New Conversation” above to reach out, or wait
            for someone to message you.
          </Typography>
        </Box>
      ) : (
        <Stack spacing={2}>
          {conversations.map((conversation) => {
            // Find the other participant
            const otherParticipant = conversation.participants.find(
              (participant) => participant._id !== user.id
            );

            const participantName = otherParticipant
              ? otherParticipant.name
              : 'Unknown User';

            const lastMessageContent = conversation.lastMessage
              ? conversation.lastMessage.content
              : 'No messages yet';

            // Truncate long snippet
            const snippet =
              lastMessageContent.length > 50
                ? lastMessageContent.substring(0, 50) + '...'
                : lastMessageContent;

            // Format date
            const updatedAt = new Date(conversation.updatedAt).toLocaleString();

            return (
              <Paper
                key={conversation._id}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
                onClick={() =>
                  navigate(`/messages/conversations/${conversation._id}`)
                }
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* Optional avatar icon, e.g. first letter of otherParticipant */}
                  <Avatar sx={{ mr: 2 }}>
                    {participantName.charAt(0).toUpperCase()}
                  </Avatar>

                  <Box flex={1}>
                    <Typography variant="subtitle1" component="div">
                      {participantName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {snippet}
                    </Typography>
                  </Box>
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 2 }}>
                    {updatedAt}
                  </Typography>
                </Box>
              </Paper>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}

export default ConversationsList;
