// src/components/layout/mobile/AdminMobileSidebar.js
import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Badge,
} from '@mui/material';
import {
  Dashboard,
  ExpandLess,
  ExpandMore,
  SettingsApplications,
  SupervisorAccount,
  Notifications,
  ExitToApp,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../../context/AuthContext';

function AdminMobileSidebar({ open, onClose }) {
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  // For tracking which submenus are open
  const [menuOpen, setMenuOpen] = React.useState({});

  // Toggle submenus
  const handleToggle = (menu) => {
    setMenuOpen((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  // Logout
  const onLogout = () => {
    logoutUser();
    navigate('/');
    onClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <List sx={{ width: 250 }}>
        {/* Admin Dashboard menu item */}
        <ListItem button onClick={() => handleToggle('adminDashboard')}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Admin Dashboard" />
          {menuOpen.adminDashboard ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.adminDashboard} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/admin');
                onClose();
              }}
            >
              <ListItemText primary="Overview" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/admin/users');
                onClose();
              }}
            >
              <ListItemText primary="Manage Users" />
            </ListItem>
          </List>
        </Collapse>

        {/* System Settings menu item */}
        <ListItem button onClick={() => handleToggle('settings')}>
          <ListItemIcon>
            <SettingsApplications />
          </ListItemIcon>
          <ListItemText primary="System Settings" />
          {menuOpen.settings ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.settings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/admin/settings/logs');
                onClose();
              }}
            >
              <ListItemText primary="View Logs" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/admin/settings/config');
                onClose();
              }}
            >
              <ListItemText primary="Configuration" />
            </ListItem>
          </List>
        </Collapse>

        {/* Another example menu item */}
        <ListItem button onClick={() => handleToggle('accounts')}>
          <ListItemIcon>
            <SupervisorAccount />
          </ListItemIcon>
          <ListItemText primary="Accounts" />
          {menuOpen.accounts ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.accounts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/admin/accounts/pending');
                onClose();
              }}
            >
              <ListItemText primary="Pending Accounts" />
            </ListItem>
            <ListItem
              button
              sx={{ pl: 4 }}
              onClick={() => {
                navigate('/admin/accounts/all');
                onClose();
              }}
            >
              <ListItemText primary="All Accounts" />
            </ListItem>
          </List>
        </Collapse>

        {/* Notifications */}
        <ListItem
          button
          onClick={() => {
            navigate('/notifications');
            onClose();
          }}
        >
          <ListItemIcon>
            <Badge badgeContent={4} color="secondary">
              <Notifications />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>

        {/* Logout */}
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default AdminMobileSidebar;
