// src/pages/UpsellServicesPage.js
import React, { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
} from '@mui/material';

/**
 * This page displays four upsell services (Brokerage, Solar, Bus2Grid, Demand)
 * each with a checkbox. Toggling each one updates the total potential savings.
 */
function UpsellServicesPage() {
  // Potential savings for each service
  const serviceOptions = [
    {
      key: 'brokerage',
      label: 'Brokerage Services',
      savings: 500,
      description: 'Secure the best energy rates and manage contracts.',
    },
    {
      key: 'solar',
      label: 'Solar Development',
      savings: 1200,
      description: 'Harness solar energy to reduce reliance on the grid.',
    },
    {
      key: 'bus2grid',
      label: 'Bus2Grid',
      savings: 2000,
      description:
        'Electrify your bus fleet and earn revenue by supporting the grid.',
    },
    {
      key: 'demand',
      label: 'Demand Services',
      savings: 800,
      description: 'Optimize energy usage to lower peak demand charges.',
    },
  ];

  // Track which services are selected
  const [selectedServices, setSelectedServices] = useState({
    brokerage: false,
    solar: false,
    bus2grid: false,
    demand: false,
  });

  // Calculate total potential savings
  const totalSavings = serviceOptions.reduce((sum, option) => {
    if (selectedServices[option.key]) {
      return sum + option.savings;
    }
    return sum;
  }, 0);

  // Handle toggling a service
  const handleToggle = (key) => {
    setSelectedServices((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Additional Services
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Select any optional services below to further reduce costs and improve
          sustainability. Your estimated savings will update in real time.
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={2}>
          {serviceOptions.map((service) => (
            <Grid item xs={12} key={service.key}>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedServices[service.key]}
                      onChange={() => handleToggle(service.key)}
                    />
                  }
                  label=""
                  sx={{ mr: 2 }}
                />

                <Box>
                  <Typography variant="h6" sx={{ mb: 0.5 }}>
                    {service.label}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                  <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
                    Potential Savings: ${service.savings.toLocaleString()}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Box
              sx={{
                mt: 3,
                p: 2,
                textAlign: 'center',
                border: 1, // numeric border instead of '1px solid'
                borderColor: 'divider', // recognized by MUI theme
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
                Estimated Total Savings
              </Typography>
              <Typography variant="h4" color="success.main">
                ${totalSavings.toLocaleString()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default UpsellServicesPage;
