// src/components/accountManager/AssignedUsersManagement.js

import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import { Edit, Person } from '@mui/icons-material';
import AuthContext from '../../context/AuthContext';
import UserProfileDialog from '../admin/dialogComponents/UserProfileDialog';

function AssignedUsersManagement() {
  const { authState } = useContext(AuthContext);
  const { token, user } = authState;
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // For profile popup
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Function to fetch assigned users
  const fetchAssignedUsers = async () => {
    try {
      const response = await fetch('/api/account-manager/assigned-users', {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to fetch users');
      }

      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  useEffect(() => {
    fetchAssignedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers for Profile Dialog
  const handleOpenProfileDialog = (listedUser) => {
    setSelectedUser(listedUser);
  };

  const handleCloseProfileDialog = () => {
    setSelectedUser(null);
  };

  // Handler for Snackbar Close
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Manage Assigned Users
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            {/* Add more columns if needed */}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((listedUser) => (
            <TableRow key={listedUser._id} hover>
              <TableCell>
                <Button
                  startIcon={<Person />}
                  onClick={() => handleOpenProfileDialog(listedUser)}
                  color="primary"
                >
                  {listedUser.name}
                </Button>
              </TableCell>
              <TableCell>{listedUser.email}</TableCell>
              {/* Add more data fields if needed */}
              <TableCell>
                {/* Account managers can edit user info */}
                <IconButton onClick={() => handleOpenProfileDialog(listedUser)} title="Edit User">
                  <Edit />
                </IconButton>
                {/* Add more action buttons if permitted */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* User Profile Dialog */}
      {selectedUser && (
        <UserProfileDialog
          open={Boolean(selectedUser)}
          onClose={handleCloseProfileDialog}
          user={selectedUser}
          token={token}
          currentUserId={user._id}
          refreshUsers={fetchAssignedUsers}
          setSnackbar={setSnackbar}
        />
      )}

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AssignedUsersManagement;
