// src/components/charts/ProductionConsumptionChart.js
import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  ButtonGroup,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { formatConsumptionData } from '../../utils/dataFormatting';

function ProductionConsumptionChart() {
  const { intervalData, loading, error } = useContext(DataContext);
  const [consumptionData, setConsumptionData] = useState([]);
  const [timeRange, setTimeRange] = useState('last24'); // Options: 'last24', 'last7d', 'last30d', 'all'


  // Handler for time range selection
  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  useEffect(() => {
    console.log('Interval Data in ConsumptionChart:', intervalData); // Debugging log
    if (intervalData.length > 0) {
      let filteredData = intervalData;

      // Determine reference time based on timeRange
      let startTime;
      let endTime;

      if (timeRange === 'all') {
        // No filtering
        startTime = null;
        endTime = null;
      } else {
        // Use the latest data point's timestamp as the reference
        const latestTimestamp = intervalData[intervalData.length - 1].startDate.getTime();
        endTime = latestTimestamp;

        switch (timeRange) {
          case 'last24':
            startTime = latestTimestamp - 24 * 60 * 60 * 1000; // 24 hours before the latest data point
            break;
          case 'last7d':
            startTime = latestTimestamp - 7 * 24 * 60 * 60 * 1000; // 7 days before
            break;
          case 'last30d':
            startTime = latestTimestamp - 30 * 24 * 60 * 60 * 1000; // 30 days before
            break;
          default:
            startTime = latestTimestamp - 24 * 60 * 60 * 1000;
        }

        console.log(
          `Filtering data from ${startTime ? new Date(startTime).toLocaleString() : 'Beginning'} to ${endTime ? new Date(endTime).toLocaleString() : 'Latest'}` // Debugging log
        );

        if (startTime) {
          filteredData = intervalData.filter(
            (block) => block.startDate.getTime() >= startTime && block.startDate.getTime() <= endTime
          );
        }
      }

      console.log('Filtered Interval Data:', filteredData); // Debugging log

      const formattedData = formatConsumptionData(filteredData);
      console.log('Formatted Consumption Data:', formattedData); // Debugging log
      setConsumptionData(formattedData);
    } else {
      setConsumptionData([]);
    }
  }, [intervalData, timeRange]);

  // Custom tooltip formatter
  const tooltipFormatter = (value) => `${value} kWh`; // Adjust unit as necessary

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Energy Consumption Over Time
      </Typography>

      {/* Time Range Selection */}
      <Box mb={2}>
        <ButtonGroup variant="outlined" size="small">
          <Button
            variant={timeRange === 'last24' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('last24')}
          >
            Last 24 Hours
          </Button>
          <Button
            variant={timeRange === 'last7d' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('last7d')}
          >
            Last 7 Days
          </Button>
          <Button
            variant={timeRange === 'last30d' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('last30d')}
          >
            Last 30 Days
          </Button>
          <Button
            variant={timeRange === 'all' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('all')}
          >
            All Time
          </Button>
        </ButtonGroup>
      </Box>

      {/* Conditional Rendering Based on Loading and Error States */}
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={300}
        >
          <CircularProgress />
          <Typography variant="subtitle1" mt={2}>
            Loading Consumption Data...
          </Typography>
        </Box>
      ) : error ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={300}
        >
          <Typography variant="subtitle1" color="error">
            {error}
          </Typography>
        </Box>
      ) : consumptionData.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={consumptionData} aria-label="Energy Consumption Over Time">
            <XAxis
              dataKey="time"
              tick={{ fontSize: 12, angle: -30, textAnchor: 'end' }}
              textAnchor="end"
              height={60}
            />
            <YAxis
              tick={{ fontSize: 12 }}
              label={{
                value: 'kWh',
                angle: -90,
                position: 'insideLeft',
                offset: 10,
                fontSize: 12,
              }}
            />
            <Tooltip formatter={tooltipFormatter} />
            <Line
              type="monotone"
              dataKey="consumption"
              stroke="#8884d8"
              strokeWidth={2}
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="body1">No consumption data available for the selected range.</Typography>
      )}
    </Box>
  );
}

export default ProductionConsumptionChart;
