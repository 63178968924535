// src/components/layout/Header.js
import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  CircularProgress,
  Box,
  Tooltip,
  Badge,
} from '@mui/material';
import {
  Notifications,
  AdminPanelSettings,
  Refresh,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

function Header() {
  const navigate = useNavigate();
  const { authState, loadUser } = useContext(AuthContext);
  const { user, loading, token } = authState;

  // Weather states (unchanged)
  const [weatherData, setWeatherData] = useState(null);
  const [weatherLoading, setWeatherLoading] = useState(true);
  const [weatherError, setWeatherError] = useState(null);

  // NEW: State for unread notifications count
  const [unreadCount, setUnreadCount] = useState(0);

  // Fetch weather on mount
  const fetchWeather = async () => {
    try {
      const response = await fetch(
        'https://api.weather.gov/gridpoints/LOT/42,87/forecast'
      );
      if (!response.ok) {
        throw new Error('Weather API response was not ok');
      }
      const data = await response.json();
      setWeatherData(data);
    } catch (error) {
      console.error('Error fetching weather data:', error);
      setWeatherError(error);
    } finally {
      setWeatherLoading(false);
    }
  };

  useEffect(() => {
    fetchWeather();
  }, []);

  // Retry user load if we have no user but are not loading
  useEffect(() => {
    let retryInterval;
    if (!loading && !user) {
      retryInterval = setInterval(() => {
        console.log('Header: Retrying to load user data...');
        loadUser();
      }, 1000);
    }
    return () => {
      if (retryInterval) {
        clearInterval(retryInterval);
      }
    };
  }, [loading, user, loadUser]);

  // NEW: Fetch notifications to get unread count
  const fetchUnreadCount = async () => {
    try {
      const response = await fetch('/api/notifications', {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }
      const data = await response.json();
      // We assume data.notifications is an array
      const unread = data.notifications.filter((n) => !n.isRead).length;
      setUnreadCount(unread);
    } catch (err) {
      console.error('Error fetching unread notifications count:', err);
      setUnreadCount(0); // fallback
    }
  };

  // Every time `user` changes (including on mount once user is loaded),
  // fetch the unread notifications
  useEffect(() => {
    if (!loading && user && token) {
      fetchUnreadCount();
    }
  }, [loading, user, token]);

  const handleRefreshWeather = () => {
    setWeatherLoading(true);
    setWeatherError(null);
    setWeatherData(null);
    fetchWeather();
  };

  // Logo click: admin => /admin, else => /dashboard
  const handleLogoClick = () => {
    if (user?.role === 'admin') {
      navigate('/admin');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        {/* Left side: Logo */}
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h6" noWrap component="div">
            <img
              src="/logo.png"
              alt="Dashboard Demo"
              style={{ height: '56px', cursor: 'pointer' }}
              onClick={handleLogoClick}
            />
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          {/*{user && user.role === 'admin' && (
            <Tooltip title="Admin Dashboard">
              <IconButton
                color="inherit"
                onClick={() => navigate('/admin')}
                size="large"
              >
                <AdminPanelSettings />
              </IconButton>
            </Tooltip>
          )}*/}

          {/* 
            If you want weather refresh button, etc., place it here
          */}

          {/* Notifications Icon with Badge */}
          <Tooltip title="Notifications">
            <IconButton
              color="inherit"
              onClick={() => {
                navigate('/notifications');
              }}
              size="large"
            >
              <Badge badgeContent={unreadCount} color="secondary">
                <Notifications />
              </Badge>
            </IconButton>
          </Tooltip>

          {/* User Profile */}
          <Tooltip title="Profile">
            <IconButton
              color="inherit"
              onClick={() => navigate('/profile')}
              size="large"
            >
              <Avatar
                alt={user ? user.name : 'User'}
                src={user ? user.avatar || '/user.jpg' : '/user.jpg'}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
