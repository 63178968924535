import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const AdminRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);
  const { loading, isAuthenticated, user } = authState;

  if (loading) {
    return <div>Loading...</div>;
  }

  // Only allow if logged in AND user.role === 'admin'
  if (!isAuthenticated || !user || user.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return children;
};

export default AdminRoute;
