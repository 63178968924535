// src/components/layout/Sidebar.js
import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';
import {
  Dashboard,
  Power,
  ExpandLess,
  ExpandMore,
  InsertDriveFile,
  Message,
  ExitToApp,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';

function Sidebar() {
  const { authState, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState({});
  // Track collapsed state
  const [collapsed, setCollapsed] = React.useState(false);

  const handleToggle = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  const onLogout = () => {
    logoutUser();
    navigate('/'); // Redirect to login page after logout
  };

  // Adjust these widths as you like
  const drawerWidth = 240;
  const collapsedWidth = 64;

  // Handler to toggle collapse
  const handleCollapseToggle = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: collapsed ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: collapsed ? collapsedWidth : drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden', // hides text overflow
        },
      }}
    >
      <Toolbar />
      <List>
        {/* Dashboard Overview, but sub-items commented out */}
        <ListItem button onClick={() => handleToggle('dashOver')}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Dashboard Overview" />
              {open.dashOver ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>
        {!collapsed && (
          <Collapse in={open.dashOver} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/*
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/dashboard')}
              >
                <ListItemText primary="All Buildings" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/dashboard')}
              >
                <ListItemText primary="Building A" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/dashboard')}
              >
                <ListItemText primary="Building B" />
              </ListItem>
              */}
            </List>
          </Collapse>
        )}

        {/* Documents */}
        <ListItem button onClick={() => navigate('/documents')}>
          <ListItemIcon>
            <InsertDriveFile />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Documents" />}
        </ListItem>

        {/* Messages */}
        <ListItem button onClick={() => navigate('/messages')}>
          <ListItemIcon>
            <Message />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Messages" />}
        </ListItem>

        {/*
        ******************** Commenting out the Energy Production Menu ********************
        <ListItem button onClick={() => handleToggle('energyProduction')}>
          <ListItemIcon>
            <Power />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Energy Production" />
              {open.energyProduction ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>
        {!collapsed && (
          <Collapse in={open.energyProduction} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/energy-production/real-time')}
              >
                <ListItemText primary="Real-Time Production" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/energy-production/historical-data')}
              >
                <ListItemText primary="Historical Data" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/energy-production/forecasts')}
              >
                <ListItemText primary="Forecasts" />
              </ListItem>
            </List>
          </Collapse>
        )}
        ************************************************************************
        */}

        {/* Logout */}
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Logout" />}
        </ListItem>
      </List>

      {/* Spacer to push collapse button to the bottom */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Collapse/Expand Button at the bottom */}
      <List>
        <ListItem
          button
          onClick={handleCollapseToggle}
          sx={{
            justifyContent: collapsed ? 'center' : 'flex-start',
          }}
        >
          <ListItemIcon>
            {/* Icon changes depending on collapsed state */}
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Collapse Sidebar" />}
        </ListItem>
      </List>
    </Drawer>
  );
}

export default Sidebar;
