import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

function NewConversation() {
  const { authState } = useContext(AuthContext);
  const { token } = authState || {};

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const toEmail = searchParams.get('to');

    if (!toEmail) {
      // No "to" param found, just go back or show an error
      navigate('/');
      return;
    }

    // Example endpoint that creates/retrieves a conversation with the "toEmail" user
    // and returns { conversationId: <string> } or { conversation: { _id: ... } }
    fetch('/api/conversations/new', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token, // if your backend requires auth
      },
      body: JSON.stringify({ toEmail }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to create or find conversation');
        }
        return res.json();
      })
      .then((data) => {
        // Suppose the server responds with { conversationId: '...' }
        if (!data.conversationId) {
          throw new Error('No conversationId returned by server');
        }
        navigate(`/conversations/${data.conversationId}`);
      })
      .catch((err) => {
        console.error('Error creating conversation:', err);
        navigate('/');
      });
  }, [location, navigate, token]);

  // Show a "Please wait..." placeholder while we process
  return <div>Starting conversation...</div>;
}

export default NewConversation;
