// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { Box, useMediaQuery } from '@mui/material';
import Header from './components/layout/Header';
import Sidebar from './components/layout/Sidebar';
import AdminSidebar from './components/layout/AdminSidebar';
import MobileHeader from './components/layout/mobile/MobileHeader';
import MobileFooter from './components/layout/mobile/mobileFooter';
import Footer from './components/layout/Footer';
import DashboardOverview from './pages/DashboardOverview';
import Documents from './pages/Documents';
import RealTime from './pages/RealTime';
import Historical from './pages/Historical';
import Forecasts from './pages/Forecasts';
import Settings from './pages/Settings';
import Notifications from './pages/Notifications';
import Profile from './pages/Profile';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';
import AdminDashboard from './components/admin/AdminDashboard';
import Upload from './components/documents/UploadDocument';
import AccountManagerRoute from './components/routing/AccountManagerRoute';
import AccountManagerDashboard from './components/accountManager/AccountManagerDashboard';
import ConversationsList from './components/messaging/ConversationList';
import ConversationDetail from './components/messaging/ConversationDetail';
import LandingPage from './pages/LandingPage';
import OnboardForm from './pages/OnboardingPage';
import './style/reset.css'; // Reset browser styles
import GreenButtonExplorer from './components/GreenButtonExplorer';
import { DataProvider } from './context/DataContext';
import BillingDetails from './components/billing/BillingDetails';
import AdditionalServices from './pages/UpsellServicesPage';
import ContactPage from './pages/ContactPage';
import EducationalResources from './pages/EducationalResources';
import NewConversation from './pages/NewConversation';


function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <Router>
          <Main />
        </Router>
      </DataProvider>
    </AuthProvider>
  );
}

function Main() {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');

  // Pages with no header or sidebar
  const noHeaderPaths = ['/', '/login', '/register', '/contact'];

  // If path starts with /admin (or if you have more admin paths),
  // we consider it an admin route
  const isAdminRoute = location.pathname.startsWith('/admin')
    || location.pathname.startsWith('/greenButtonExplorer');

  // Whether to show the header
  const showHeader = !noHeaderPaths.includes(location.pathname);

  // Choose which sidebar to show, if any
  // - On admin routes, show AdminSidebar
  // - On other private routes, show user Sidebar
  // - On no-header paths, show no sidebar
  let sidebarToShow = null;
  if (showHeader && !isMobile) {
    sidebarToShow = isAdminRoute ? <AdminSidebar /> : <Sidebar />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {/* Header */}
      {showHeader && (isMobile ? <MobileHeader /> : <Header />)}

      {/* Content + (possible) Sidebar */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexGrow: 1,
        }}
      >
        {sidebarToShow}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: isMobile ? 2 : 3,
            pt: isMobile && showHeader ? '80px' : 0,
            pb: isMobile ? '60px' : 0,
          }}
        >
          {/* Keep space for the desktop header if we have a sidebar */}
          {sidebarToShow && !isMobile && <Box sx={{ height: '80px' }} />}

          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/contact" element={<ContactPage />} />


            {/* Admin Routes */}
            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AdminRoute>
                  {/* Your ManageUsers component here */}
                  <div>Manage Users Page</div>
                </AdminRoute>
              }
            />
            <Route
              path="/admin/settings/logs"
              element={
                <AdminRoute>
                  <div>System Logs</div>
                </AdminRoute>
              }
            />
            <Route
              path="/admin/settings/config"
              element={
                <AdminRoute>
                  <div>System Config</div>
                </AdminRoute>
              }
            />
            <Route
              path="/greenButtonExplorer"
              element={
                <AdminRoute>
                  <GreenButtonExplorer />
                </AdminRoute>
              }
            />

            {/* Account Manager Route (example) */}
            <Route
              path="/accountManager"
              element={
                <AccountManagerRoute>
                  <AccountManagerDashboard />
                </AccountManagerRoute>
              }
            />

            {/* Private/User Routes */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <DashboardOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="/documents"
              element={
                <PrivateRoute>
                  <Documents />
                </PrivateRoute>
              }
            />
            <Route
              path="/onboard"
              element={
                <PrivateRoute>
                  <OnboardForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/documents/upload"
              element={
                <PrivateRoute>
                  <Upload />
                </PrivateRoute>
              }
            />
            <Route
              path="/energy-production/real-time"
              element={
                <PrivateRoute>
                  <RealTime />
                </PrivateRoute>
              }
            />
            <Route
              path="/energy-production/historical-data"
              element={
                <PrivateRoute>
                  <Historical />
                </PrivateRoute>
              }
            />
            <Route
              path="/energy-production/forecasts"
              element={
                <PrivateRoute>
                  <Forecasts />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages/conversations/:conversationId"
              element={
                <PrivateRoute>
                  <ConversationDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <PrivateRoute>
                  <ConversationsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/billingDetails"
              element={
                <PrivateRoute>
                  <BillingDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/onboard"
              element={
                <PrivateRoute>
                  <OnboardForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/additionalServices"
              element={
                <PrivateRoute>
                  <AdditionalServices />
                </PrivateRoute>
              }
            />
            <Route path="/educational-resources" element={<PrivateRoute><EducationalResources /></PrivateRoute>} />
            <Route path="/conversations/new" element={<PrivateRoute><NewConversation /></PrivateRoute>} />
            <Route path="/conversations/:conversationId" element={<PrivateRoute><ConversationDetail /></PrivateRoute>} />
          </Routes>
        </Box>
      </Box>

      {/* Footer */}
      {!isMobile && <Footer />}
      {/* If you want a mobile footer on small screens, uncomment this: */}
      {/* {isMobile && <MobileFooter />} */}
    </Box>
  );
}

export default App;
