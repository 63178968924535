// src/components/documents/DocLoader.js

import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import AuthContext from '../../context/AuthContext'; // Adjust the path if necessary
import DocumentsPanel from './DocumentsPanel';

function DocLoader() {
  const { authState } = useContext(AuthContext);
  const { token, user, isAuthenticated } = authState;

  const [userId, setUserId] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  // Function to initialize user data
  const initializeUserData = () => {
    if (isAuthenticated && user && token) {
      setUserId(user._id);
    } else {
      setSnackbar({ open: true, message: 'User not authenticated.', severity: 'error' });
    }
    setLoadingUser(false);
  };

  useEffect(() => {
    initializeUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, token]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loadingUser) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated || !user || !token) {
    return (
      <Box p={4}>
        <Typography variant="h6" color="error">
          You must be logged in to view your documents.
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={4}>
      {/*<Typography variant="h5" gutterBottom>
        Your Documents
      </Typography>*/}
      <DocumentsPanel userId={userId} token={token} />
      
      {/* Snackbar for Notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DocLoader;
