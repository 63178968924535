// src/components/metrics/TotalSavings.js
import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { DataContext } from '../../context/DataContext';

function TotalSavings() {
  const { metrics } = useContext(DataContext);
  if (!metrics) return null;

  const totalSavings = metrics.totalSavings;

  // Use Intl.NumberFormat (or toLocaleString) to format with commas
  const formattedSavings = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,  // or add if you want decimals
    maximumFractionDigits: 0,
  }).format(totalSavings);

  return (
    <div>
      <Typography variant="subtitle1">Total Savings</Typography>
      <Typography variant="h4">${formattedSavings}</Typography>
    </div>
  );
}

export default TotalSavings;
