// src/components/metrics/CarbonReduction.js
import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { DataContext } from '../../context/DataContext';

function CarbonReduction() {
  const { metrics } = useContext(DataContext);
  if (!metrics) return null;

  const carbonReduction = metrics.carbonReduction;

  return (
    <div>
      <Typography variant="subtitle1">Carbon Reduction</Typography>
      <Typography variant="h4">{carbonReduction} tons CO₂</Typography>
    </div>
  );
}

export default CarbonReduction;
