import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Avatar,
  TextField,
  IconButton,
} from '@mui/material';
import { Send } from '@mui/icons-material';

function MessagingSection({
  loadingMessages,
  messages,
  socketRef,
  conversation,
  adminUser,
  userFields,
}) {
  const [messageContent, setMessageContent] = useState('');
  const bottomRef = useRef(null);

  // Auto-scroll
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = () => {
    if (!messageContent.trim() || !conversation || !socketRef.current) return;

    socketRef.current.emit('sendMessage', {
      conversationId: conversation._id,
      content: messageContent,
      sender: adminUser._id,
      receiver: userFields._id,
    });
    setMessageContent('');
  };

  if (loadingMessages) {
    return <CircularProgress size={24} />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Direct Messages with {userFields.name}
      </Typography>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: 2,
          height: 300,
          overflowY: 'auto',
          p: 1,
          mb: 1,
        }}
      >
        <List dense>
          {messages.map((msg) => (
            <ListItem key={msg._id}>
              <Avatar
                sx={{ mr: 1, width: 32, height: 32 }}
                alt={msg.sender.name || 'Unknown'}
              >
                {msg.sender.name ? msg.sender.name.charAt(0) : '?'}
              </Avatar>
              <ListItemText
                primary={msg.content}
                secondary={
                  msg.sender._id === adminUser._id
                    ? `You, ${new Date(msg.dateSent).toLocaleString()}`
                    : `${msg.sender.name}, ${new Date(msg.dateSent).toLocaleString()}`
                }
              />
            </ListItem>
          ))}
          <div ref={bottomRef} />
        </List>
      </Box>

      <Box display="flex" alignItems="center">
        <TextField
          fullWidth
          placeholder="Type a message..."
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <IconButton color="primary" onClick={handleSendMessage} sx={{ ml: 1 }}>
          <Send />
        </IconButton>
      </Box>
    </Box>
  );
}

export default MessagingSection;
