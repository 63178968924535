import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  AlertTitle,
} from '@mui/material';

/**
 * A powerful "raw JSON" editor for the user + userData objects.
 * 
 * Props:
 *  - userFields (object)
 *  - setUserFields (function)
 *  - userDataFields (object)
 *  - setUserDataFields (function)
 *  - toggleFullEditMode (function)
 *  - initialUserData (object)
 *  - saveAllChanges (function): does the PUT with { user, userData }
 */
function FullUserEditor({
  userFields,
  setUserFields,
  userDataFields,
  setUserDataFields,
  toggleFullEditMode,
  initialUserData,
  saveAllChanges,
}) {
  const [rawUser, setRawUser] = useState('');
  const [rawUserData, setRawUserData] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const safeUser = { ...userFields };
    if (safeUser.password) {
      delete safeUser.password;
    }
    try {
      setRawUser(JSON.stringify(safeUser, null, 2));
    } catch {
      setRawUser('{}');
    }

    try {
      setRawUserData(JSON.stringify(userDataFields, null, 2));
    } catch {
      setRawUserData('{}');
    }
  }, [userFields, userDataFields]);

  const handleSaveAll = () => {
    setErrorMsg('');

    let parsedUser;
    let parsedUserData;
    try {
      parsedUser = JSON.parse(rawUser);
    } catch (err) {
      setErrorMsg(`Error parsing User JSON: ${err.message}`);
      return;
    }

    try {
      parsedUserData = JSON.parse(rawUserData);
    } catch (err) {
      setErrorMsg(`Error parsing UserData JSON: ${err.message}`);
      return;
    }

    // set parent's states
    setUserFields(parsedUser);
    setUserDataFields(parsedUserData);

    // call parent's saveAll
    saveAllChanges();
  };

  const handleRevert = () => {
    if (!initialUserData) return;
    const { userData, ...restUser } = initialUserData;
    const safeUser = { ...restUser };
    if (safeUser.password) {
      delete safeUser.password;
    }
    setRawUser(JSON.stringify(safeUser, null, 2));
    setRawUserData(JSON.stringify(userData || {}, null, 2));
    setErrorMsg('');
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="h6">Powerful JSON Editor (User & UserData)</Typography>
        <Box>
          <Button
            variant="contained"
            color="success"
            onClick={handleSaveAll}
            sx={{ mr: 2 }}
          >
            Save All
          </Button>
          <Button variant="outlined" onClick={handleRevert} sx={{ mr: 2 }}>
            Revert to Original
          </Button>
          <Button variant="outlined" onClick={toggleFullEditMode}>
            Close Editor
          </Button>
        </Box>
      </Box>

      {errorMsg && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>JSON Parse Error</AlertTitle>
          {errorMsg}
        </Alert>
      )}

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        <strong>User Document (minus password)</strong>
      </Typography>
      <TextField
        label="Raw User JSON"
        multiline
        minRows={8}
        fullWidth
        value={rawUser}
        onChange={(e) => setRawUser(e.target.value)}
        sx={{ mb: 3, fontFamily: 'monospace' }}
      />

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        <strong>UserData Document</strong>
      </Typography>
      <TextField
        label="Raw UserData JSON"
        multiline
        minRows={8}
        fullWidth
        value={rawUserData}
        onChange={(e) => setRawUserData(e.target.value)}
        sx={{ mb: 3, fontFamily: 'monospace' }}
      />
    </Box>
  );
}

export default FullUserEditor;
