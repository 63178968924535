// src/components/auth/Login.js
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Grid,
} from '@mui/material';
import AuthContext from '../../context/AuthContext';

function Login() {
  // 1) Hooks
  const authContext = useContext(AuthContext); // Hook #1
  const navigate = useNavigate();              // Hook #2
  const [formData, setFormData] = useState({ email: '', password: '' }); // Hook #3
  const [alert, setAlert] = useState(null);                             // Hook #4

  // 2) Set page title
  useEffect(() => {
    document.title = 'Login';
  }, []);

  // 3) Safely extract what we need
  const { loginUser, authState } = authContext || {};
  const { loading, isAuthenticated, user } = authState || {};

  // 4) Redirect if logged in
  useEffect(() => {
    // Only run if we have an authContext and we've loaded user
    if (authContext && !loading && isAuthenticated) {
      // Admin -> /admin
      if (user?.role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    }
  }, [authContext, loading, isAuthenticated, user, navigate]);

  // 5) If AuthContext doesn't exist, bail
  if (!authContext) {
    console.error('AuthContext is undefined. Check <AuthProvider> setup.');
    return null;
  }

  // 6) Form events
  const onChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setAlert(null);

    try {
      await loginUser({
        email: formData.email,
        password: formData.password,
      });
      // We rely on the useEffect above to do the actual redirect after login
    } catch (error) {
      if (error.response?.data?.errors) {
        setAlert(error.response.data.errors[0].msg || 'Login failed');
      } else {
        setAlert('Login failed');
      }
    }
  };

  // 7) Render
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          <img
            src="/logo.png"
            alt="Dashboard Demo"
            style={{ height: '60px' }}
          />
        </Typography>

        {alert && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {alert}
          </Alert>
        )}

        <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={onChange}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={onChange}
          />

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
            Sign In
          </Button>

          <Grid container justifyContent="flex-end">
            <Grid item>
              {/* 
              <Typography
                variant="body2"
                component="a"
                href="/register"
                sx={{ textDecoration: 'none' }}
              >
                Don't have an account? Sign Up
              </Typography>
              */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
