// src/utils/dataFormatting.js

// Example: Prepare data for a chart that shows consumption over time
export const formatConsumptionData = (intervalData) => {
    return intervalData.map((block) => ({
      time: block.startDate.toLocaleString('default', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }), // e.g., "Apr 2, 04:00 AM"
      consumption: block.value / 1000, 
    }));
  };
  
  // Example: Calculate total billing, average cost, etc.
  export const calculateBillingMetrics = (intervalData) => {
    const totalBillingCents = intervalData.reduce((acc, block) => acc + block.cost, 0);
    const averageCostPerKwhCents =
      intervalData.length > 0 ? totalBillingCents / intervalData.length : 0;
    const previousBillingCents = 0; // Define logic if you have previous data
  
    // Convert hundred-thousandths to dollars
    const totalBilling = totalBillingCents / 100000;
    const averageCostPerKwh = averageCostPerKwhCents / 100000;
    const previousBilling = previousBillingCents / 100000;
  
    return {
      totalBilling,
      averageCostPerKwh,
      previousBilling,
    };
  };
  
  export const formatBillingHistory = (intervalData) => {
    const billingHistory = {};
  
    intervalData.forEach((block, index) => {
      if (!block.startDate || isNaN(block.cost)) {
        console.warn(`Skipping block at index ${index} due to missing startDate or invalid cost.`);
        return; // Skip invalid entries
      }
  
      // Ensure startDate is a Date object
      let parsedDate = block.startDate;
      if (!(parsedDate instanceof Date)) {
        // Attempt to parse if it's a string
        parsedDate = new Date(block.startDate);
        if (isNaN(parsedDate.getTime())) {
          console.warn(`Block at index ${index} has an invalid startDate after parsing:`, block.startDate);
          return; // Skip invalid dates
        }
      }
  
      // Aggregate by day
      const day = parsedDate.toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }); // e.g., "Apr 01, 2019"
  
      if (!billingHistory[day]) {
        billingHistory[day] = 0;
      }
      billingHistory[day] += block.cost; // Cost is in hundred-thousandths
    });
  
    // Convert hundred-thousandths to dollars
    const billingHistoryInDollars = Object.entries(billingHistory).map(
      ([day, amountCents]) => ({
        day,
        amount: amountCents / 100000, // Convert to dollars
      })
    );
  
    console.log('Formatted Billing History In Dollars:', billingHistoryInDollars); // Debugging log
  
    return billingHistoryInDollars;
  };
  