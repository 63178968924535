import React, { useEffect, useContext } from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import your existing components:
import CurrentEnergyProduction from '../components/metrics/CurrentEnergyProduction';
import CurrentEnergyConsumption from '../components/metrics/CurrentEnergyConsumption';
import TotalSavings from '../components/metrics/TotalSavings';
import CarbonReduction from '../components/metrics/CarbonReduction';
import ProductionConsumptionChart from '../components/charts/ProductionConsumptionChart';
import EnergySourcePieChart from '../components/charts/EnergySourcePieChart';
import NotificationsPanel from '../components/notifications/NotificationsPanel';
import BillingSummary from '../components/metrics/BillingSummary';
import BillingChart from '../components/charts/BillingChart';
// If needed:
// import BillingDetails from '../components/billing/BillingDetails';

import AuthContext from '../context/AuthContext';

function DashboardOverview() {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  // If your token is stored in authState.token:
  const token = authState?.token;

  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  // Safely read the name, or default to “Example School District”
  const districtName = authState?.user?.schoolName || 'Example School District';

  // Generate a LaTeX-based PDF, then download
  const handleGenerateReport = async () => {
    try {
      const response = await fetch('/api/report', { method: 'GET' });
      if (!response.ok) {
        throw new Error('Failed to generate report');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'EnergyReport.pdf';
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  // Create/find a conversation with support@econergy.net, then navigate to it
  const handleContactSupport = async () => {
    try {
      const response = await fetch('/api/messages/conversations/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token, // Make sure this is valid
        },
        body: JSON.stringify({ toEmail: 'support@econergy.net' }),
      });

      if (!response.ok) {
        throw new Error('Failed to create or find conversation');
      }

      const data = await response.json();
      if (!data.conversation || !data.conversation._id) {
        throw new Error('No valid conversation returned from server');
      }

      // Navigate to the conversation detail page
      navigate(`/conversations/${data.conversation._id}`);
    } catch (error) {
      console.error('Error contacting support:', error);
    }
  };

  // Open your educational resources page
  const handleAccessEducationalResources = () => {
    navigate('/educational-resources');
  };

  return (
    <Grid container spacing={3} alignItems="stretch">
      {/* Page Title */}
      <Grid item xs={12}>
        <Typography variant="h4">{districtName}</Typography>
      </Grid>

      {/* Key Metrics Summary */}
      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <CurrentEnergyProduction />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <CurrentEnergyConsumption />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <TotalSavings />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <CarbonReduction />
        </Paper>
      </Grid>

      {/* Historical Energy Consumption */}
      <Grid item xs={12} md={9}>
        <Paper elevation={3} sx={{ p: 2, height: '90%' }}>
          <ProductionConsumptionChart />
        </Paper>
      </Grid>

      {/* Energy Source Breakdown */}
      <Grid item xs={12} md={3}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6">Energy Source Breakdown</Typography>
          <EnergySourcePieChart />
        </Paper>
      </Grid>

      {/* Billing Summary */}
      <Grid item xs={12} md={3}>
        <Paper elevation={3} sx={{ p: 2, height: '90%' }}>
          <BillingSummary />
        </Paper>
      </Grid>

      {/* Billing Chart */}
      <Grid item xs={12} md={9}>
        <Paper elevation={3} sx={{ p: 2, height: '90%' }}>
          <BillingChart />
        </Paper>
      </Grid>

      {/* Notifications Panel */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 2, height: '90%' }}>
          <Typography variant="h6">Notifications</Typography>
          <NotificationsPanel />
        </Paper>
      </Grid>

      {/* Quick Action Buttons */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '90%',
            gap: 2,
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6">Quick Actions</Typography>
          <Button variant="contained" onClick={handleGenerateReport}>
            Generate Report
          </Button>
          <Button variant="contained" onClick={handleContactSupport}>
            Contact Support
          </Button>
          <Button variant="contained" onClick={handleAccessEducationalResources}>
            Access Educational Resources
          </Button>
        </Paper>
      </Grid>

      {/* Spacer */}
      <Grid item xs={12}>
        <Typography variant="h4"> </Typography>
      </Grid>
    </Grid>
  );
}

export default DashboardOverview;
