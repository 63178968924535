// src/components/billing/BillingDetails.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  CircularProgress,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

function BillingDetails() {
  const [billingDetails, setBillingDetails] = useState([]); // Holds detailed billing items
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchBillingDetails = async () => {
      try {
        // Fetch billing details from the backend
        const response = await axios.get('/api/green-button/Batch/Subscription/6'); // Adjust endpoint if necessary
        const jsonData = response.data;

        // Debugging: Inspect the fetched JSON data
        console.log('Fetched JSON Data for BillingDetails:', jsonData);

        const details = [];

        if (jsonData.feed && Array.isArray(jsonData.feed.entry)) {
          // Example: Assume detailed billing items are under entries titled "Billing Details"
          const billingDetailEntries = jsonData.feed.entry.filter(
            (entry) => entry.title === 'Billing Details' && entry.content['espi:BillingItem']
          );

          billingDetailEntries.forEach((entry) => {
            const billingItemsArray = entry.content['espi:BillingItem'];

            if (Array.isArray(billingItemsArray)) {
              billingItemsArray.forEach((item) => {
                const description = item['espi:description'] || 'N/A';
                const amount = parseFloat(item['espi:amount']) || 0;
                const date = item['espi:date'] || 'N/A';

                details.push({
                  description,
                  amount,
                  date,
                });
              });
            }
          });
        }

        setBillingDetails(details);
      } catch (err) {
        console.error('Error fetching or parsing billing details data:', err);
        setError('Failed to load billing details.');
      } finally {
        setLoading(false);
      }
    };

    fetchBillingDetails();
  }, []); // Runs once on mount

  // Render loading indicator
  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={300}
      >
        <CircularProgress />
        <Typography variant="subtitle1" mt={2}>
          Loading Billing Details...
        </Typography>
      </Box>
    );
  }

  // Render error message
  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={300}
      >
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  // Render billing details table
  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Billing Details
      </Typography>
      {billingDetails.length > 0 ? (
        <TableContainer component={Paper} style={{ maxHeight: 400 }}>
          <Table stickyHeader aria-label="billing details table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Description</strong></TableCell>
                <TableCell><strong>Amount ($)</strong></TableCell>
                <TableCell><strong>Date</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billingDetails.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.amount.toFixed(2)}</TableCell>
                  <TableCell>{item.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No billing details available.</Typography>
      )}
    </Box>
  );
}

export default BillingDetails;
