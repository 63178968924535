// src/components/auth/Register.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AuthContext from '../../context/AuthContext';

function Register() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  });

  const [alert, setAlert] = useState(null);

  // Destructure formData
  const { name, email, password, password2 } = formData;

  // Ensure authContext is defined
  if (!authContext) {
    console.error(
      'AuthContext is undefined. Make sure AuthProvider is wrapping your component tree.'
    );
    return null; // or display an appropriate message to the user
  }

  const { registerUser } = authContext;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== password2) {
      setAlert('Passwords do not match');
      return;
    }

    try {
      await registerUser({ name, email, password });
      navigate('/dashboard'); // Redirect to dashboard or desired page after registration
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setAlert(error.response.data.errors[0].msg || 'Registration failed');
      } else {
        setAlert('Registration failed');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {alert && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {alert}
          </Alert>
        )}
        <Box component="form" onSubmit={onSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                required
                fullWidth
                label="Name"
                autoFocus
                value={name}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                required
                fullWidth
                label="Email Address"
                value={email}
                onChange={onChange}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                required
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={onChange}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password2"
                required
                fullWidth
                label="Confirm Password"
                type="password"
                value={password2}
                onChange={onChange}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Typography
                variant="body2"
                component="a"
                href="/login"
                sx={{ textDecoration: 'none' }}
              >
                Already have an account? Sign in
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
