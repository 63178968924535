// src/components/admin/AdminDashboard.js
import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { PersonAdd, Search, Visibility } from '@mui/icons-material';
import AuthContext from '../../context/AuthContext';
import UserProfileDialog from './dialogComponents/UserProfileDialog';

function AdminDashboard() {
  const { authState } = useContext(AuthContext);
  const { token, user: currentAdmin } = authState;

  // For storing all users in the table
  const [users, setUsers] = useState([]);
  // For search
  const [searchQuery, setSearchQuery] = useState('');

  // Pagination state
  const [pageNumber, setPageNumber] = useState(0);
  const PAGE_SIZE = 25;

  // Create new user dialog
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');

  // User profile dialog
  const [selectedUser, setSelectedUser] = useState(null);

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // ============== Bulk Selection State ==============
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedManagerId, setSelectedManagerId] = useState('');
  const [bulkMessage, setBulkMessage] = useState('');

  // =========================
  // 1) Fetch All Users
  // =========================
  const fetchAllUsers = async () => {
    try {
      const response = await fetch('/api/admin/users', {
        headers: { 'x-auth-token': token },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || 'Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line
  }, []);

  // =========================
  // 2) Generate Temp Password
  // =========================
  const generateTempPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  // =========================
  // 3) Create New User
  // =========================
  const handleOpenCreateDialog = () => {
    setNewUserEmail('');
    setTempPassword(generateTempPassword());
    setCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const handleCreateUser = async () => {
    if (!newUserEmail.trim()) {
      alert('Please enter a valid email.');
      return;
    }
    try {
      const res = await fetch('/api/admin/users/newUser', {
        method: 'POST',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newUserEmail, password: tempPassword }),
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.msg || 'Failed to create new user');
      }
      await res.json();

      setSnackbar({
        open: true,
        message: `New user created! Temp password: ${tempPassword}`,
        severity: 'success',
      });

      fetchAllUsers();
      setCreateDialogOpen(false);
    } catch (err) {
      console.error(err);
      setSnackbar({
        open: true,
        message: err.message,
        severity: 'error',
      });
    }
  };

  // =========================
  // 4) Filter Users by Search
  // =========================
  const filteredUsers = users.filter((u) => {
    const combined = [
      u.name,
      u.email,
      u.role,
      u.schoolName,
      u.schoolDistrict,
      u.city,
      u.county,
      u.userData?.metrics?.currentProduction,
      u.userData?.metrics?.totalSavings,
      u.userData?.metrics?.carbonReduction,
    ]
      .filter(Boolean)
      .join(' ')
      .toLowerCase();

    return combined.includes(searchQuery.toLowerCase());
  });

  // If the user changes the search query, go back to page 0
  useEffect(() => {
    setPageNumber(0);
  }, [searchQuery]);

  // =========================
  // 5) Pagination
  // =========================
  const totalPages = Math.ceil(filteredUsers.length / PAGE_SIZE);

  // We slice the filteredUsers for the current page
  const startIndex = pageNumber * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const pagedUsers = filteredUsers.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber((prev) => prev - 1);
    }
  };
  const handleNextPage = () => {
    if (pageNumber < totalPages - 1) {
      setPageNumber((prev) => prev + 1);
    }
  };

  // =========================
  // 6) View User Profile
  // =========================
  const handleViewProfile = (user) => {
    setSelectedUser(user);
  };

  const handleCloseProfileDialog = () => {
    setSelectedUser(null);
  };

  // =========================
  // 7) Snackbar close
  // =========================
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // =========================
  // 8) Bulk Selection Logic
  // =========================

  // Toggle the checkbox for a single user
  const handleToggleSelect = (userId) => {
    setSelectedUserIds((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  // Check if user is selected
  const isSelected = (userId) => selectedUserIds.includes(userId);

  // ============= Bulk Assign Manager =============
  // Suppose you want to do it for "accountManager" role
  const possibleManagers = users.filter((u) => u.role === 'accountManager');

  const handleBulkAssignManager = async () => {
    if (!selectedManagerId) {
      alert('Please select a manager first.');
      return;
    }
    if (selectedUserIds.length === 0) {
      alert('No users selected.');
      return;
    }
    try {
      const res = await fetch('/api/admin/users/bulkAssignManager', {
        method: 'PUT',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userIds: selectedUserIds,
          managerId: selectedManagerId,
        }),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.msg || 'Failed to assign manager in bulk');
      }
      setSnackbar({
        open: true,
        message: data.msg || 'Manager assigned successfully.',
        severity: 'success',
      });
      // Refresh user list
      fetchAllUsers();
      // Clear selected
      setSelectedUserIds([]);
      setSelectedManagerId('');
    } catch (err) {
      console.error('Bulk assign manager error:', err);
      setSnackbar({
        open: true,
        message: err.message,
        severity: 'error',
      });
    }
  };

  // ============= Bulk Send Notification =============
  const handleBulkSendNotification = async () => {
    if (selectedUserIds.length === 0) {
      alert('No users selected.');
      return;
    }
    if (!bulkMessage.trim()) {
      alert('Please enter a notification message.');
      return;
    }
    try {
      const res = await fetch('/api/notifications/bulk', {
        method: 'POST',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userIds: selectedUserIds,
          message: bulkMessage,
        }),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || 'Failed to send notifications');
      }
      setSnackbar({
        open: true,
        message: data.message || 'Notifications sent successfully.',
        severity: 'success',
      });
      // Clear
      setSelectedUserIds([]);
      setBulkMessage('');
    } catch (err) {
      console.error('Bulk notification error:', err);
      setSnackbar({
        open: true,
        message: err.message,
        severity: 'error',
      });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      {/* Top row: Title, Search, Create User */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Account Manager Dashboard
        </Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <Search color="action" />
          <TextField
            placeholder="Search users..."
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            variant="contained"
            startIcon={<PersonAdd />}
            onClick={handleOpenCreateDialog}
          >
            Create User
          </Button>
        </Box>
      </Box>

      {/* Bulk Actions Row */}
      <Box
        sx={{
          mb: 2,
          p: 2,
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          flexWrap: 'wrap',
          backgroundColor: '#f5f5f5',
          borderRadius: 2,
        }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Assign Manager</InputLabel>
          <Select
            value={selectedManagerId}
            label="Assign Manager"
            onChange={(e) => setSelectedManagerId(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {possibleManagers.map((mgr) => (
              <MenuItem key={mgr._id} value={mgr._id}>
                {mgr.name || mgr.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleBulkAssignManager}
          disabled={!selectedManagerId || selectedUserIds.length === 0}
        >
          Bulk Assign Manager
        </Button>

        <TextField
          label="Notification Message"
          value={bulkMessage}
          onChange={(e) => setBulkMessage(e.target.value)}
          sx={{ minWidth: 300 }}
        />
        <Button
          variant="contained"
          onClick={handleBulkSendNotification}
          disabled={selectedUserIds.length === 0 || !bulkMessage.trim()}
        >
          Send Notification
        </Button>
      </Box>

      {/* Table of Users */}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Role</strong></TableCell>
              <TableCell><strong>Current Production</strong></TableCell>
              <TableCell><strong>Total Savings</strong></TableCell>
              <TableCell><strong>Carbon Reduction</strong></TableCell>
              <TableCell align="right"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedUsers.map((u) => {
              const userId = u._id;
              const checked = isSelected(userId);
              return (
                <TableRow key={userId}>
                  <TableCell>
                    <Checkbox
                      checked={checked}
                      onChange={() => handleToggleSelect(userId)}
                    />
                  </TableCell>
                  <TableCell>{u.name}</TableCell>
                  <TableCell>{u.email}</TableCell>
                  <TableCell>{u.role}</TableCell>
                  <TableCell>{u.userData?.metrics?.currentProduction ?? 'N/A'}</TableCell>
                  <TableCell>{u.userData?.metrics?.totalSavings ?? 'N/A'}</TableCell>
                  <TableCell>{u.userData?.metrics?.carbonReduction ?? 'N/A'}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleViewProfile(u)}
                      size="small"
                      title="View User Profile"
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            {pagedUsers.length === 0 && (
              <TableRow>
                <TableCell colSpan={8}>No matching users found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={pageNumber === 0}
        >
          Previous
        </Button>
        <Typography>
          Page {pageNumber + 1} of {totalPages === 0 ? 1 : totalPages}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleNextPage}
          disabled={pageNumber >= totalPages - 1 || totalPages === 0}
        >
          Next
        </Button>
      </Box>

      {/* CREATE NEW USER DIALOG */}
      <Dialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <TextField
            label="User Email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            fullWidth
          />
          <TextField
            label="Temporary Password"
            value={tempPassword}
            fullWidth
            disabled
            helperText="A random password was generated. Provide this to the user."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancel</Button>
          <Button onClick={handleCreateUser} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* USER PROFILE DIALOG */}
      {selectedUser && (
        <UserProfileDialog
          open={!!selectedUser}
          onClose={handleCloseProfileDialog}
          user={selectedUser}
          token={token}
          currentUserId={currentAdmin?._id}
          refreshUsers={fetchAllUsers}
          setSnackbar={setSnackbar}
        />
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminDashboard;
