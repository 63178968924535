// src/pages/OnboardingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import OnboardSchoolForm from '../components/forms/OnboardSchoolForm';

function OnboardingPage() {
  const navigate = useNavigate();

  const handleOnboardingSubmit = async (formData) => {
    try {
      // Send data to /api/onboarding
      const res = await fetch('/api/onboarding', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token'), // or from context
        },
        body: JSON.stringify(formData),
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.msg || 'Failed to submit onboarding');
      }
      // If successful, go to dashboard (example)
      navigate('/dashboard');
    } catch (error) {
      console.error('Onboarding error:', error);
      alert(error.message);
    }
  };

  return <OnboardSchoolForm onSubmit={handleOnboardingSubmit} />;
}

export default OnboardingPage;
