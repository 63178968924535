// src/pages/Forecasts.js
import React, {useEffect} from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import ProductionConsumptionChart from '../components/charts/ProductionConsumptionChart';

function Forecasts() {
  useEffect(() => {
    document.title = 'Forecasts';
  }, []);
  return (
    <Grid item xs={12} md={8}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">Forecasts</Typography>
        <ProductionConsumptionChart />
      </Paper>
    </Grid>
  );
}

export default Forecasts;
