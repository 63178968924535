// src/components/routing/AccountManagerRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const AccountManagerRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);

  if (authState.loading) {
    return <div>Loading...</div>;
  }

  if (
    !authState.isAuthenticated ||
    !authState.user ||
    authState.user.role !== 'accountManager'
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AccountManagerRoute;
