// src/pages/LandingPage.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: '#fff',
  height: '80vh',
  padding: theme.spacing(8, 2),
  background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),
    url('/hero.jpg') 
    center/cover no-repeat`,
}));

const FeatureCard = ({ title, description, imageUrl, imageAlt }) => (
  <Paper variant="outlined" sx={{ p: 4, textAlign: 'center', height: '100%' }}>
    <Box
      component="img"
      src={imageUrl}
      alt={imageAlt}
      sx={{
        width: '100%',
        height: 'auto',
        maxHeight: '200px',
        marginBottom: 2,
        objectFit: 'cover',
      }}
    />
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {description}
    </Typography>
  </Paper>
);

function LandingPage() {
  return (
    <>
      {/* Navigation Bar */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <img
              src="/logo.png"
              alt="Dashboard Demo"
              style={{ height: '56px', cursor: 'pointer' }}
            />
          </Typography>
          <Button 
            href="/login"
            color="primary"
            variant="contained"
            sx={{ marginLeft: 'auto' }}
          >
            Log In
          </Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <HeroSection>
        {/* Optional Container around the text to keep it centered while image spans full width */}
        <Container maxWidth="md">
          <Typography variant="h3" component="h1" gutterBottom>
            Empower Your School District with Smarter Energy Management
          </Typography>
          <Typography variant="h6" paragraph>
            Track, optimize, and reduce your district’s energy consumption with real-time insights and expert guidance.
          </Typography>
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="/contact" // Changed from /onboarding to /contact
            >
              Contact Us
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              href="#learn-more"
              sx={{ ml: 2 }}
            >
              Learn More
            </Button>
          </Box>
        </Container>
      </HeroSection>

      {/* Features Section */}
      <Box py={8} id="learn-more">
        <Container maxWidth="lg">
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Key Features and Benefits
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            From solar integration to cogen solutions, we provide the tools and insights you need.
          </Typography>

          <Grid container spacing={4} mt={4}>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Real-Time Analytics"
                description="Monitor energy consumption and costs across all your buildings instantly."
                imageUrl="/analytics.jpg"
                imageAlt="Real-Time Analytics"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Optimized Sourcing"
                description="Identify opportunities for solar, cogen, and bus-to-grid solutions tailored to your district."
                imageUrl="/install.jpg"
                imageAlt="Optimized Sourcing"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Cost Savings"
                description="Reduce expenses with data-driven strategies and long-term energy planning."
                imageUrl="/savings.jpg"
                imageAlt="Cost Savings"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* About the Illinois Energy Consortium */}
      <Box py={8} bgcolor="#f9f9f9">
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom align="center">
            About the Illinois Energy Consortium
          </Typography>
          <Typography variant="body1" paragraph align="center">
            The Illinois Energy Consortium (IEC) is dedicated to empowering school districts
            with sustainable, cost-effective energy solutions. Our platform helps you navigate
            the complex energy landscape, ensuring you make informed decisions for a brighter future.
          </Typography>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box py={8}>
        <Container maxWidth="sm" align="center">
          <Typography variant="h4" component="h2" gutterBottom>
            Start Your Journey
          </Typography>
          <Typography variant="body1" paragraph>
            Join hundreds of school districts in Illinois who are taking control of their energy footprint.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/contact" // Changed from /onboarding to /contact
            sx={{ mt: 3 }}
          >
            Contact Us
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default LandingPage;
