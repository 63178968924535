// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: true,
    user: null,
  });

  // 1) This function just loads user info from the server
  const loadUser = async () => {
    try {
      const res = await axios.get('/api/auth/user');
      setAuthState((prev) => ({
        ...prev,
        isAuthenticated: true,
        loading: false,
        user: res.data,
      }));
    } catch (err) {
      console.error('loadUser error:', err.response?.data || err.message);
      setAuthState((prev) => ({
        ...prev,
        isAuthenticated: false,
        loading: false,
        user: null,
      }));
    }
  };

  // 2) Whenever 'token' changes, set up the default axios header,
  //    then call loadUser() if we have a token.
  useEffect(() => {
    if (authState.token) {
      // Put the token on all future axios requests
      setAuthToken(authState.token);
      // Then attempt to load the user
      loadUser();
    } else {
      // Remove any leftover token
      setAuthToken(null);
      // No token means not authenticated
      setAuthState((prev) => ({
        ...prev,
        isAuthenticated: false,
        loading: false,
        user: null,
      }));
    }
  }, [authState.token]);

  // 3) Register user
  const registerUser = async (formData) => {
    try {
      const res = await axios.post('/api/auth/register', formData);
      // Store token
      localStorage.setItem('token', res.data.token);
      // Update state, which triggers the effect above to load user
      setAuthState((prev) => ({
        ...prev,
        token: res.data.token,
        // Keep loading = true until loadUser finishes
        loading: true,
      }));
    } catch (err) {
      console.error('Error registering user:', err.response?.data || err.message);
      throw err;
    }
  };

  // 4) Login user
  const loginUser = async (formData) => {
    try {
      const res = await axios.post('/api/auth/login', formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      // Store token
      localStorage.setItem('token', res.data.token);
      // Update state to trigger loadUser via the effect
      setAuthState((prev) => ({
        ...prev,
        token: res.data.token,
        loading: true, 
      }));
    } catch (err) {
      console.error('AuthContext loginUser error:', err.response?.data || err.message);
      throw err;
    }
  };

  // 5) Logout user
  const logoutUser = () => {
    localStorage.removeItem('token');
    setAuthState({
      token: null,
      isAuthenticated: false,
      loading: false,
      user: null,
    });
    setAuthToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        registerUser,
        loginUser,
        logoutUser,
        loadUser, 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
