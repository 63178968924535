// src/components/metrics/BillingSummary.js
import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { Typography, CircularProgress, Box } from '@mui/material';
import { formatCurrency } from '../../utils/currencyFormatter';

function BillingSummary() {
  const { billingMetrics, loading, error } = useContext(DataContext);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return 'N/A';
    const change = ((current - previous) / previous) * 100;
    return `${change.toFixed(2)}%`;
  };

  // Render loading indicator
  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress />
        <Typography variant="subtitle1" mt={2}>
          Loading Billing Summary...
        </Typography>
      </Box>
    );
  }

  // Render error message
  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  // Render billing summary
  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Billing Summary
      </Typography>
      <Typography variant="subtitle1">
        <strong>Total Billing Amount:</strong> {formatCurrency(billingMetrics.totalBilling)}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Average Cost per kWh:</strong> {formatCurrency(billingMetrics.averageCostPerKwh)}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Change from Previous Period:</strong>{' '}
        {calculatePercentageChange(
          billingMetrics.totalBilling,
          billingMetrics.previousBilling
        )}
      </Typography>
    </Box>
  );
}

export default BillingSummary;
