// src/components/admin/dialogComponents/UserProfileDialog.js
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';

import AuthContext from '../../../context/AuthContext';
import BasicUserView from './BasicUserView';
import FullUserEditor from './FullUserEditor';
import MessagingSection from './MessagingSection';

function UserProfileDialog({
  open,
  onClose,
  user: initialUserData,
  token,
  currentUserId,
  refreshUsers,
  setSnackbar,
}) {
  const navigate = useNavigate();
  const { authState, loadUser } = useContext(AuthContext);
  const { user: adminUser } = authState; // The admin currently logged in

  // Basic edit vs full edit
  const [editMode, setEditMode] = useState(false);
  const [fullEditMode, setFullEditMode] = useState(false);

  // local states for user + userData
  const [userFields, setUserFields] = useState({});
  const [userDataFields, setUserDataFields] = useState({});

  // messaging states
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [conversation, setConversation] = useState(null);
  const socketRef = useRef(null);

  // ---------------- 1) Initialize on open ----------------
  useEffect(() => {
    if (initialUserData) {
      const { userData, ...rest } = initialUserData;
      setUserFields(rest);
      setUserDataFields(userData || {});
    }
  }, [initialUserData]);

  // ---------------- 2) Socket.io for messaging ----------------
  useEffect(() => {
    if (!open) return;
    const newSocket = io('/', { withCredentials: true });
    socketRef.current = newSocket;

    newSocket.on('connect', () => {
      console.log('Admin socket connected for user dialog:', newSocket.id);
    });

    newSocket.on('messageReceived', (newMsg) => {
      if (conversation && newMsg.conversationId === conversation._id) {
        setMessages((prev) => [...prev, newMsg]);
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, [open, conversation]);

  // ---------------- 3) Fetch/create direct conversation ----------------
  useEffect(() => {
    if (open && initialUserData && adminUser) {
      fetchOrCreateDirectConversation();
    }
    // eslint-disable-next-line
  }, [open]);

  const fetchOrCreateDirectConversation = async () => {
    setLoadingMessages(true);
    try {
      const res = await fetch('/api/messages/conversations/direct', {
        method: 'POST',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otherUserId: initialUserData._id }),
      });
      const data = await res.json();
      if (!res.ok || data.error) {
        throw new Error(data.error || 'Failed to create or find conversation');
      }
      setConversation(data.conversation);
      setMessages(data.messages || []);
      setLoadingMessages(false);

      if (socketRef.current) {
        socketRef.current.emit('join conversation', data.conversation._id);
      }
    } catch (err) {
      console.error('Error fetching/creating direct convo:', err);
      setMessages([]);
      setLoadingMessages(false);
    }
  };

  // ---------------- 4) Close dialog & reset states ----------------
  const handleCloseDialog = () => {
    if (initialUserData) {
      const { userData, ...rest } = initialUserData;
      setUserFields(rest);
      setUserDataFields(userData || {});
    }
    setEditMode(false);
    setFullEditMode(false);
    onClose();
  };

  // ---------------- BASIC EDIT MODE ----------------
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const saveBasicUserChanges = async () => {
    if (!userFields._id) return;
    try {
      const userId = userFields._id;
      const { _id, ...restUser } = userFields;

      // We only update top-level user doc, no userData
      const res = await fetch(`/api/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: restUser, // rename from userUpdates -> user
          userData: {},   // no userData changes in basic mode
        }),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.msg || 'Failed to update user');
      }

      setSnackbar({
        open: true,
        message: data.msg || 'User updated successfully',
        severity: 'success',
      });

      if (userId === currentUserId) {
        await loadUser();
      }

      refreshUsers();
    } catch (err) {
      console.error('Error saving basic user changes:', err);
      setSnackbar({ open: true, message: err.message, severity: 'error' });
    }
  };

  // ---------------- FULL EDIT MODE ----------------
  const toggleFullEditMode = () => {
    setFullEditMode((prev) => !prev);
  };

  const saveAllChanges = async () => {
    if (!userFields._id) return;
    try {
      const userId = userFields._id;
      const { _id, ...restUser } = userFields;

      // advanced: we pass both user + userData
      const res = await fetch(`/api/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: restUser,          // rename userUpdates -> user
          userData: userDataFields // rename userDataUpdates -> userData
        }),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.msg || 'Failed to update user + userData');
      }

      setSnackbar({
        open: true,
        message: data.msg || 'User updated successfully',
        severity: 'success',
      });

      if (userId === currentUserId) {
        await loadUser();
      }

      refreshUsers();
    } catch (err) {
      console.error('Error saving user + userData changes:', err);
      setSnackbar({ open: true, message: err.message, severity: 'error' });
    }
  };

  // ---------------- Admin actions (Reset, Promote, Demote, Delete) ----------------
  const handleResetPassword = async () => {
    const newPassword = prompt('Enter new password:');
    if (newPassword) {
      try {
        const response = await fetch(`/api/admin/users/${userFields._id}/reset-password`, {
          method: 'PUT',
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ password: newPassword }),
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.msg || 'Failed to reset password');
        }
        setSnackbar({ open: true, message: data.message, severity: 'success' });
      } catch (error) {
        console.error('Error resetting password:', error);
        setSnackbar({ open: true, message: error.message, severity: 'error' });
      }
    }
  };

  const handlePromote = async () => {
    try {
      const response = await fetch(`/api/admin/users/${userFields._id}/promote`, {
        method: 'PUT',
        headers: { 'x-auth-token': token },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.msg || 'Failed to promote user');
      }
      setSnackbar({ open: true, message: data.msg, severity: 'success' });
      refreshUsers();
      onClose();
    } catch (error) {
      console.error('Error promoting user:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleDemote = async () => {
    try {
      const response = await fetch(`/api/admin/users/${userFields._id}/demote`, {
        method: 'PUT',
        headers: { 'x-auth-token': token },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.msg || 'Failed to demote user');
      }
      setSnackbar({ open: true, message: data.msg, severity: 'success' });
      refreshUsers();
      onClose();
    } catch (error) {
      console.error('Error demoting user:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleDeleteUser = async () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await fetch(`/api/admin/users/${userFields._id}`, {
          method: 'DELETE',
          headers: { 'x-auth-token': token },
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || 'Failed to delete user');
        }
        setSnackbar({ open: true, message: data.message, severity: 'success' });
        refreshUsers();
        onClose();
      } catch (error) {
        console.error('Error deleting user:', error);
        setSnackbar({ open: true, message: error.message, severity: 'error' });
      }
    }
  };

  const handleGoToUserDashboard = () => {
    navigate(`/dashboard?impersonate=${userFields._id}`);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle>
        User Profile
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {!fullEditMode ? (
          <BasicUserView
            editMode={editMode}
            toggleEditMode={toggleEditMode}
            toggleFullEditMode={toggleFullEditMode}
            userFields={userFields}
            setUserFields={setUserFields}
            handleGoToUserDashboard={handleGoToUserDashboard}
            saveBasicUserChanges={saveBasicUserChanges}
          />
        ) : (
          <FullUserEditor
            userFields={userFields}
            setUserFields={setUserFields}
            userDataFields={userDataFields}
            setUserDataFields={setUserDataFields}
            toggleFullEditMode={toggleFullEditMode}
            initialUserData={initialUserData}
            saveAllChanges={saveAllChanges}
          />
        )}

        {!fullEditMode && (
          <MessagingSection
            loadingMessages={loadingMessages}
            messages={messages}
            socketRef={socketRef}
            conversation={conversation}
            adminUser={adminUser}
            userFields={userFields}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {/* LEFT side: Admin actions */}
        <Box>
          {userFields._id !== currentUserId && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleResetPassword}
                sx={{ mr: 1 }}
              >
                Reset Password
              </Button>
              {userFields?.role !== 'admin' ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePromote}
                  sx={{ mr: 1 }}
                >
                  Promote to Admin
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleDemote}
                  sx={{ mr: 1 }}
                >
                  Demote from Admin
                </Button>
              )}
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteUser}
              >
                Delete User
              </Button>
            </>
          )}
        </Box>

        {/* RIGHT side: Cancel or Close */}
        {fullEditMode ? (
          <Button
            onClick={() => {
              if (initialUserData) {
                const { userData, ...rest } = initialUserData;
                setUserFields(rest);
                setUserDataFields(userData || {});
              }
              setFullEditMode(false);
            }}
            color="inherit"
          >
            Cancel Full Edit
          </Button>
        ) : (
          <Button onClick={handleCloseDialog} color="inherit">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default UserProfileDialog;
