import React from 'react';
import { Typography, Container } from '@mui/material';

function EducationalResources() {
  return (
    <Container sx={{ paddingTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        Educational Resources
      </Typography>
      <Typography variant="body1">
        This page will eventually provide detailed information to school districts about
        energy production, consumption, and ways to optimize efficiency. Stay tuned!
      </Typography>
    </Container>
  );
}

export default EducationalResources;
