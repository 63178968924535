// src/components/accountManager/AccountManagerDashboard.js

import React from 'react';
import { Container, Typography } from '@mui/material';
import AssignedUsersManagement from './AssignedUsersManagement';

function AccountManagerDashboard() {
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 4 }}>
        Account Manager Dashboard
      </Typography>
      <AssignedUsersManagement />
    </Container>
  );
}

export default AccountManagerDashboard;
