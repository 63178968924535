// src/pages/Settings.js
import React, {useEffect} from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import DocumentsPanel from '../components/documents/DocumentsPanel';

function Settings() {
  useEffect(() => {
    document.title = 'Settings';
  }, []);
  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">Documents</Typography>
        <DocumentsPanel />
      </Paper>
    </Grid>
  );
}

export default Settings;
