// src/components/GreenButtonExplorer.js
import React, { useState } from 'react';

function GreenButtonExplorer() {
  // State to store current 'view'
  const [subscriptionId, setSubscriptionId] = useState(1);
  const [usagePoints, setUsagePoints] = useState([]);
  const [meterReadings, setMeterReadings] = useState([]);
  const [intervalBlocks, setIntervalBlocks] = useState([]);

  // For logging errors or info
  const [error, setError] = useState('');

  // Step 1: Fetch UsagePoints for a subscription
  async function fetchUsagePoints() {
    try {
      setError('');
      // Example: GET /api/green-button/Subscription/:subscriptionId/UsagePoint
      const res = await fetch(`/api/green-button/Subscription/${subscriptionId}/UsagePoint`);
      if (!res.ok) {
        throw new Error(`Failed to fetch usage points: ${res.status}`);
      }
      const data = await res.json();
      // data.feed.entry might contain the usage points
      // We'll parse them in a helper function below
      setUsagePoints(parseUsagePoints(data));
      setMeterReadings([]);
      setIntervalBlocks([]);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  }

  // Step 2: Fetch MeterReadings for a chosen UsagePoint
  async function fetchMeterReadings(usagePointId) {
    try {
      setError('');
      // Example: GET /api/green-button/Subscription/:subscriptionId/UsagePoint/:usagePointId/MeterReading
      const res = await fetch(`/api/green-button/Subscription/${subscriptionId}/UsagePoint/${usagePointId}/MeterReading`);
      if (!res.ok) {
        throw new Error(`Failed to fetch meter readings: ${res.status}`);
      }
      const data = await res.json();
      // We'll parse them in a helper
      setMeterReadings(parseMeterReadings(data));
      setIntervalBlocks([]);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  }

  // Step 3: Fetch IntervalBlocks for a chosen MeterReading
  async function fetchIntervalBlocks(usagePointId, meterReadingId) {
    try {
      setError('');
      // Example: GET /api/green-button/Subscription/:subscriptionId/UsagePoint/:usagePointId/MeterReading/:meterReadingId/IntervalBlock
      const res = await fetch(`/api/green-button/Subscription/${subscriptionId}/UsagePoint/${usagePointId}/MeterReading/${meterReadingId}/IntervalBlock`);
      if (!res.ok) {
        throw new Error(`Failed to fetch interval blocks: ${res.status}`);
      }
      const data = await res.json();
      setIntervalBlocks(parseIntervalBlocks(data));
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  }

  // ------------------------
  // Parsing Helpers
  // ------------------------

  // Example usage: parseUsagePoints(data) where data is the JSON from GET UsagePoint
  function parseUsagePoints(jsonData) {
    if (!jsonData.feed || !jsonData.feed.entry) {
      return [];
    }
    const entries = Array.isArray(jsonData.feed.entry)
      ? jsonData.feed.entry
      : [jsonData.feed.entry];
    // Each entry might have a <title> or <id> or links
    // We'll do something simple: store usagePointId from link or ID (if present)
    return entries.map((e) => ({
      title: e.title || 'No title',
      // You might parse usagePointId from the link or e.id if it’s embedded
      // For example, if e.link has a "self" link: /UsagePoint/3
      // This is just a placeholder. You need to parse the actual ID from e.link or e.id
      usagePointId: extractIdFromLink(e.link),
    }));
  }

  // Example usage: parseMeterReadings(data)
  function parseMeterReadings(jsonData) {
    if (!jsonData.feed || !jsonData.feed.entry) {
      return [];
    }
    const entries = Array.isArray(jsonData.feed.entry)
      ? jsonData.feed.entry
      : [jsonData.feed.entry];
    return entries.map((e) => ({
      title: e.title || 'No title',
      meterReadingId: extractIdFromLink(e.link), // parse from e.link or e.id
    }));
  }

  // parse IntervalBlocks data
  function parseIntervalBlocks(jsonData) {
    if (!jsonData.feed || !jsonData.feed.entry) {
      return [];
    }
    const entries = Array.isArray(jsonData.feed.entry)
      ? jsonData.feed.entry
      : [jsonData.feed.entry];

    // Each entry might contain an IntervalBlock with IntervalReadings
    // We’ll just store them as raw data for now
    return entries.map((entry) => {
      return {
        intervalBlock: entry.content?.IntervalBlock || {},
      };
    });
  }

  // Example link parser (very naive). You’d do real regex or string splitting:
  function extractIdFromLink(links) {
    if (!Array.isArray(links)) return null;
    const selfLink = links.find((l) => l.$.rel === 'self');
    if (!selfLink) return null;

    // selfLink.$.href might be something like
    // "https://sandbox.../Subscription/1/UsagePoint/3"
    // We'll just return the trailing number if found
    const parts = selfLink.$.href.split('/');
    return parts[parts.length - 1];
  }

  // ------------------------

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Green Button Explorer</h2>
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      <div>
        <label>Subscription ID: </label>
        <input
          type="number"
          value={subscriptionId}
          onChange={(e) => setSubscriptionId(e.target.value)}
        />
        <button onClick={fetchUsagePoints}>Fetch UsagePoints</button>
      </div>

      <hr />

      {/* Display usage points */}
      <h3>Usage Points</h3>
      {usagePoints.length === 0 && <p>No usage points found yet.</p>}
      <ul>
        {usagePoints.map((up, idx) => (
          <li key={idx}>
            <button onClick={() => fetchMeterReadings(up.usagePointId)}>
              {up.title} (ID: {up.usagePointId})
            </button>
          </li>
        ))}
      </ul>

      <hr />

      {/* Display meter readings */}
      <h3>Meter Readings</h3>
      {meterReadings.length === 0 && <p>No meter readings found yet.</p>}
      <ul>
        {meterReadings.map((mr, idx) => (
          <li key={idx}>
            <button
              onClick={() => {
                // We also need the usagePointId to fetch IntervalBlock
                // In a real UI, you'd store the usagePointId in meterReading
                // or keep track of the currently selected usagePoint.
                // For this example, let's assume usagePointId=3 is known:
                fetchIntervalBlocks(3, mr.meterReadingId);
              }}
            >
              {mr.title} (MeterReadingID: {mr.meterReadingId})
            </button>
          </li>
        ))}
      </ul>

      <hr />

      {/* Display interval blocks */}
      <h3>Interval Blocks</h3>
      {intervalBlocks.length === 0 && <p>No interval blocks fetched yet.</p>}
      <ul>
        {intervalBlocks.map((ib, idx) => (
          <li key={idx}>
            IntervalBlock Data: {JSON.stringify(ib.intervalBlock)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GreenButtonExplorer;
