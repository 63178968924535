// src/components/messaging/ConversationDetail.js

import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import AuthContext from '../../context/AuthContext';

function ConversationDetail() {
  const { authState } = useContext(AuthContext);
  const { token, user } = authState;
  // e.g. user = { _id: "abc123", name: "Admin User", ... }

  const { conversationId } = useParams();

  const [messages, setMessages] = useState([]);
  const [newMessageContent, setNewMessageContent] = useState('');
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);

  // 1) Fetch existing messages
  useEffect(() => {
    if (!conversationId || !token) return;

    fetch(`/api/messages/conversations/${conversationId}/messages`, {
      headers: { 'x-auth-token': token },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.messages) {
          setMessages(data.messages);
        } else if (data.error) {
          console.error('Error fetching messages:', data.error);
        }
      })
      .catch((err) => console.error('Fetch messages error:', err));
  }, [conversationId, token]);

  // 2) Initialize Socket.io
  useEffect(() => {
    if (!conversationId) return;

    // Connect to server; adjust URL if needed (e.g., 'http://localhost:5000')
    const newSocket = io('/', { withCredentials: true });
    socketRef.current = newSocket;

    // Join the room
    newSocket.emit('join conversation', conversationId);

    // Listen for incoming messages
    newSocket.on('messageReceived', (newMessage) => {
      if (newMessage.conversationId === conversationId) {
        setMessages((prev) => [...prev, newMessage]);
      }
    });

    // Cleanup
    return () => {
      newSocket.disconnect();
    };
  }, [conversationId]);

  // 3) Scroll to bottom whenever messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  // 4) Send a message
  const handleSendMessage = () => {
    if (!newMessageContent.trim()) return;
    if (!socketRef.current) return;

    socketRef.current.emit('sendMessage', {
      conversationId,
      content: newMessageContent,
      sender: user._id,
    });

    setNewMessageContent('');
  };

  // Send on "Enter"
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',    // fill the horizontal space
        height: '80vh',   // fixed height for the messenger
        border: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
      }}
    >
      {/* Scrollable Messages Area */}
      <Box
        ref={messagesEndRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          backgroundColor: 'grey.100',
        }}
      >
        <List disablePadding>
          {messages.map((msg) => {
            const isMe = msg.sender?._id === user._id;
            const senderName = isMe
              ? 'Me'
              : msg.sender?.name || 'Unknown';

            return (
              <ListItem
                key={msg._id}
                sx={{
                  justifyContent: isMe ? 'flex-end' : 'flex-start',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: isMe ? 'primary.main' : 'grey.300',
                    color: isMe ? 'common.white' : 'text.primary',
                    borderRadius: 2,
                    p: 1,
                    maxWidth: '70%',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 'bold', mb: 0.25, opacity: 0.9 }}
                  >
                    {senderName}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    {msg.content}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      textAlign: 'right',
                      mt: 0.5,
                      opacity: 0.7,
                    }}
                  >
                    {new Date(msg.dateSent).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Typography>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* Send Message Input */}
      <Box
        sx={{
          display: 'flex',
          p: 2,
          borderTop: '1px solid #ccc',
        }}
      >
        <TextField
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          placeholder="Type a message..."
          value={newMessageContent}
          onChange={(e) => setNewMessageContent(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          onClick={handleSendMessage}
          sx={{ ml: 1 }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
}

export default ConversationDetail;
