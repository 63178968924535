// src/pages/Profile.js
import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  CircularProgress,
  Alert,
  Grid,
  Paper,
} from '@mui/material';
import AuthContext from '../context/AuthContext';

function ProfileOverview() {
  const { authState, setAuthState, loadUser } = useContext(AuthContext);
  const { token, user, loading } = authState;

  // Track editable fields, including greenButtonApiKey
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    greenButtonApiKey: '',
  });

  const [profilePicture, setProfilePicture] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || '',
        password: '',
        greenButtonApiKey: user.greenButtonApiKey || '',
      });
    }
  }, [user]);

  // Retry logic if user is null
  useEffect(() => {
    let retryInterval;
    if (!loading && !user) {
      retryInterval = setInterval(() => {
        console.log('Retrying to load user data...');
        loadUser();
      }, 2000);
    }
    return () => {
      if (retryInterval) {
        clearInterval(retryInterval);
      }
    };
  }, [loading, user, loadUser]);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Update Profile
  const handleUpdateProfile = async () => {
    // Basic validation
    if (!formData.name || !formData.email) {
      setMessage({ type: 'error', text: 'Name and Email are required.' });
      return;
    }

    setMessage({ type: '', text: '' });

    try {
      const endpoint = '/api/profile';
      // Clone formData
      const payload = { ...formData };

      // Remove password if it's empty
      if (!payload.password) {
        delete payload.password;
      }

      // PUT request
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMsg =
          data.errors?.map((err) => err.msg).join(', ') ||
          data.message ||
          'Failed to update profile.';
        throw new Error(errorMsg);
      }

      // Update auth context
      setAuthState({
        ...authState,
        user: data.user,
      });

      setMessage({ type: 'success', text: 'Profile updated successfully.' });
      setEditMode(false);
      setFormData((prev) => ({ ...prev, password: '' })); // clear password
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ type: 'error', text: error.message });
    }
  };

  // Upload Profile Picture
  const handleUploadPicture = async () => {
    if (!profilePicture) {
      setMessage({ type: 'error', text: 'Please select a profile picture.' });
      return;
    }

    setUploading(true);
    setMessage({ type: '', text: '' });

    const formDataPicture = new FormData();
    formDataPicture.append('profilePicture', profilePicture);

    try {
      const endpoint = '/api/profile/picture';
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'x-auth-token': token,
        },
        body: formDataPicture,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to upload profile picture.');
      }

      setAuthState({
        ...authState,
        user: data.user,
      });

      setMessage({ type: 'success', text: 'Profile picture updated.' });
      setProfilePicture(null);
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      setMessage({ type: 'error', text: error.message });
    } finally {
      setUploading(false);
    }
  };

  // Logout
  const handleLogout = () => {
    setAuthState({ token: null, user: null, isAuthenticated: false, loading: false });
    localStorage.removeItem('token');
  };

  // Loading states
  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (!user) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Profile
      </Typography>

      {message.text && (
        <Alert severity={message.type} sx={{ mb: 2 }}>
          {message.text}
        </Alert>
      )}

      <Grid container spacing={4}>
        {/* User Info */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              User Information
            </Typography>

            {!editMode ? (
              <>
                <Typography variant="body1">
                  <strong>Name:</strong> {user.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {user.email}
                </Typography>
                <Typography variant="body1">
                  <strong>Green Button API Key:</strong>{' '}
                  {user.greenButtonApiKey
                    ? user.greenButtonApiKey
                    : 'Not set'}
                </Typography>

                <Button variant="contained" sx={{ mt: 2 }} onClick={() => setEditMode(true)}>
                  Edit Profile
                </Button>
              </>
            ) : (
              <>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="New Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  helperText="Leave blank to keep current password."
                />

                {/* New Green Button Connect API Key Field */}
                <TextField
                  label="Green Button API Key"
                  name="greenButtonApiKey"
                  type="text"
                  value={formData.greenButtonApiKey}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  helperText="Enter your Green Button Connect API key here."
                />

                <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateProfile}
                    disabled={uploading}
                  >
                    {uploading ? <CircularProgress size={24} /> : 'Save Changes'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setEditMode(false);
                      // Reset form data to what is currently on the user object
                      setFormData({
                        name: user.name || '',
                        email: user.email || '',
                        password: '',
                        greenButtonApiKey: user.greenButtonApiKey || '',
                      });
                      setMessage({ type: '', text: '' });
                    }}
                    disabled={uploading}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </Paper>
        </Grid>

        {/* Profile Picture */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Profile Picture
            </Typography>
            <Avatar
              alt={user.name}
              src={
                user.profilePicture
                  ? `/api/documents/${user.profilePicture}/download`
                  : ''
              }
              sx={{ width: 120, height: 120, margin: '0 auto', mb: 2 }}
            />
            <Button
              variant="contained"
              component="label"
              disabled={uploading}
              sx={{ mt: 1 }}
            >
              {uploading ? <CircularProgress size={24} /> : 'Upload New Picture'}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setProfilePicture(e.target.files[0])}
              />
            </Button>
            {profilePicture && (
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={handleUploadPicture}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : 'Save Picture'}
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProfileOverview;
