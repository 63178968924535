// src/theme.js
import { createTheme } from '@mui/material/styles';
import { blue, amber, grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light', // 'light' or 'dark'
    primary: {
      main: '#9cb63b',//'#0D1F3E',
    },
    secondary: {
      main: '#f6e344',//amber[500],
    },
    background: {
      default: grey[100],
      paper: '#FFFFFF',
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#FFFFFF',//'#0D1F3E',
          color: '#555555',
        },
      },
    },
  },
});

export default theme;
