// src/components/charts/EnergySourcePieChart.js
import React, { useContext } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DataContext } from '../../context/DataContext';

function EnergySourcePieChart() {
  const { metrics } = useContext(DataContext);
  if (!metrics) return null;

  const data = metrics.energySources || [];
  const COLORS = ['#9cb63b', '#ef9f54', '#76a3db'];

  return (
    <ResponsiveContainer width="100%" height={250}>
      <PieChart style={{ fontFamily: 'Inter' }}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="source"
          cx="50%"
          cy="50%"
          outerRadius={80}
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip style={{ fontFamily: 'Inter' }} />
        <Legend style={{ fontFamily: 'Inter' }} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default EnergySourcePieChart;
