// src/components/documents/UploadDocument.js

import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

function UploadDocument({ userId, token, refreshDocuments }) {
  const [file, setFile] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const initPath = "documents/users"

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setSnackbar({ open: true, message: 'Please select a file to upload.', severity: 'warning' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`/api/admin/`+initPath+`/${userId}/documents/upload`, {
        method: 'POST',
        headers: {
          'x-auth-token': token,
          // Do NOT set 'Content-Type' manually when using FormData
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to upload document');
      }

      const data = await response.json();
      setSnackbar({ open: true, message: data.message, severity: 'success' });
      setFile(null);
      if (refreshDocuments) {
        refreshDocuments(); // Refresh the documents list after upload
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box mt={2}>
      <Typography variant="subtitle1" gutterBottom>
        Upload New Document:
      </Typography>
      <input
        accept="application/pdf"
        style={{ display: 'none' }}
        id={`upload-button-${userId}`}
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor={`upload-button-${userId}`}>
        <Button variant="contained" component="span" startIcon={<CloudUpload />} color="primary">
          Choose File
        </Button>
      </label>
      {file && <Typography variant="body2" sx={{ mt: 1 }}>{file.name}</Typography>}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleUpload}
        sx={{ mt: 1 }}
        disabled={!file}
      >
        Upload Document
      </Button>

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UploadDocument;
