// src/components/notifications/NotificationsPanel.js
import React, { useEffect, useState, useContext } from 'react';
import { List, ListItem, ListItemText, IconButton, Badge } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import AuthContext from '../../context/AuthContext';

function NotificationsPanel() {
  const { authState } = useContext(AuthContext);
  const { token } = authState;
  const [notifications, setNotifications] = useState([]);

  // Fetch notifications when the component mounts
  useEffect(() => {
    const fetchNotifications = () => {
      fetch('/api/notifications', {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch notifications');
          }
          return response.json();
        })
        .then((data) => {
          setNotifications(data.notifications);
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
    };

    fetchNotifications();
  }, [token]);

  // Function to mark a notification as read
  const markAsRead = (notificationId) => {
    fetch(`/api/notifications/${notificationId}/read`, {
      method: 'PUT',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to mark notification as read');
        }
        // Update the local state
        setNotifications((prevNotifications) =>
          prevNotifications.map((notif) =>
            notif._id === notificationId ? { ...notif, isRead: true } : notif
          )
        );
      })
      .catch((error) => {
        console.error('Error updating notification:', error);
      });
  };

  return (
    <List>
      {notifications.map((notification) => (
        <ListItem
          key={notification._id}
          divider
          style={{
            backgroundColor: notification.isRead ? '#f0f0f0' : '#ffffff',
          }}
        >
          <ListItemText
            primary={notification.message}
            secondary={new Date(notification.date).toLocaleString()}
          />
          {!notification.isRead && (
            <IconButton onClick={() => markAsRead(notification._id)}>
              <Badge color="primary" variant="dot">
                <CheckCircle />
              </Badge>
            </IconButton>
          )}
        </ListItem>
      ))}
    </List>
  );
}

export default NotificationsPanel;
