// src/components/charts/BillingChart.js
import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  ButtonGroup,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

function BillingChart() {
  const { billingHistory, loading, error } = useContext(DataContext);
  const [chartData, setChartData] = useState([]);
  const [timeRange, setTimeRange] = useState('all'); // Default to 'all'

  // Handler for time range selection
  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  useEffect(() => {
    console.log('Billing History:', billingHistory); // Debugging log
    if (billingHistory.length > 0) {
      // Adjust the billing data based on the selected time range
      let filteredBillingHistory = billingHistory;

      if (timeRange !== 'all') {
        // Determine the reference time based on the latest billing data
        const latestBillingDateStr = billingHistory[billingHistory.length - 1].day;
        const latestBillingDate = new Date(latestBillingDateStr);
        if (isNaN(latestBillingDate.getTime())) {
          console.error('Latest billing entry has an invalid day:', latestBillingDateStr);
          setChartData([]);
          return;
        }

        let startTime;

        switch (timeRange) {
          case 'last24':
            startTime = new Date(latestBillingDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours before
            break;
          case 'last7d':
            startTime = new Date(latestBillingDate.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days before
            break;
          case 'last30d':
            startTime = new Date(latestBillingDate.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days before
            break;
          default:
            startTime = null;
        }

        console.log(
          `Filtering billing data from ${startTime ? startTime.toLocaleDateString() : 'Beginning'} to ${latestBillingDate.toLocaleDateString()}`
        ); // Debugging log

        if (startTime) {
          filteredBillingHistory = billingHistory.filter((entry) => {
            const entryDate = new Date(entry.day);
            return entryDate >= startTime && entryDate <= latestBillingDate;
          });
        }
      }

      console.log('Filtered Billing History:', filteredBillingHistory); // Debugging log

      setChartData(filteredBillingHistory);
    } else {
      setChartData([]);
    }
  }, [billingHistory, timeRange]);

  // Custom tooltip formatter
  const tooltipFormatter = (value) => `$${value.toFixed(2)}`; // Already converted to dollars

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Billing Trends Over Time
      </Typography>

      {/* Time Range Selection */}
      <Box mb={2}>
        <ButtonGroup variant="outlined" size="small">
          <Button
            variant={timeRange === 'last24' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('last24')}
          >
            Last 24 Hours
          </Button>
          <Button
            variant={timeRange === 'last7d' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('last7d')}
          >
            Last 7 Days
          </Button>
          <Button
            variant={timeRange === 'last30d' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('last30d')}
          >
            Last 30 Days
          </Button>
          <Button
            variant={timeRange === 'all' ? 'contained' : 'outlined'}
            onClick={() => handleTimeRangeChange('all')}
          >
            All Time
          </Button>
        </ButtonGroup>
      </Box>

      {/* Conditional Rendering Based on Loading and Error States */}
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={300}
        >
          <CircularProgress />
          <Typography variant="subtitle1" mt={2}>
            Loading Billing History...
          </Typography>
        </Box>
      ) : error ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={300}
        >
          <Typography variant="subtitle1" color="error">
            {error}
          </Typography>
        </Box>
      ) : chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} aria-label="Billing Trends Over Time" id="billing-chart">
            <XAxis
              dataKey="day"
              tick={{ fontSize: 12 }}
              angle={-45}
              textAnchor="end"
              height={60}
            />
            <YAxis
              tickFormatter={(value) => `$${value}`}
              tick={{ fontSize: 12 }}
              label={{
                value: 'USD',
                angle: -90,
                position: 'insideLeft',
                offset: 10,
                fontSize: 12,
              }}
            />
            <Tooltip formatter={tooltipFormatter} />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="#FF8042"
              strokeWidth={2}
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="body1">No billing history available for the selected range.</Typography>
      )}
    </Box>
  );
}

export default BillingChart;
