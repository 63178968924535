// src/components/layout/mobile/MobileHeader.js
import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MobileSidebar from './MobileSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import AuthContext from '../../../context/AuthContext';

function MobileHeader() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { authState } = useContext(AuthContext);
  const { user } = authState || {};

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Decide which sidebar to render: if the user is admin => AdminMobileSidebar
  // otherwise => MobileSidebar
  let SidebarComponent = MobileSidebar;
  if (user?.role === 'admin') {
    SidebarComponent = AdminMobileSidebar;
  }

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#9cb63b' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {user?.role === 'admin' ? 'Admin Panel' : 'User Dashboard'}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Conditionally render the chosen sidebar */}
      <SidebarComponent open={drawerOpen} onClose={handleDrawerToggle} />
    </>
  );
}

export default MobileHeader;
