// src/components/metrics/CurrentEnergyProduction.js
import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { DataContext } from '../../context/DataContext';

function CurrentEnergyProduction() {
  const { metrics } = useContext(DataContext);

  // If there's a chance metrics is null/undefined, do a check:
  if (!metrics) return null;

  const production = metrics.currentProduction; // in kWh

  return (
    <div>
      <Typography variant="subtitle1">Current Energy Production</Typography>
      <Typography variant="h4">{production} kWh</Typography>
    </div>
  );
}

export default CurrentEnergyProduction;
