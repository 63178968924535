// src/components/layout/Footer.js
import React from 'react';
import { Typography, Link, Box } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ p: 2, backgroundColor: '#f5f5f5', mt: 'auto' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} Econergy. All rights reserved.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Link href="/privacy-policy">Privacy Policy</Link> | <Link href="/terms-of-service">Terms of Service</Link>
      </Typography>
    </Box>
  );
}

export default Footer;
