// src/components/metrics/CurrentEnergyConsumption.js
import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import { Typography, CircularProgress, Box, Paper } from '@mui/material';
import { formatConsumptionData } from '../../utils/dataFormatting';

function CurrentEnergyConsumption() {
  const { intervalData, loading, error } = useContext(DataContext);
  const [consumption, setConsumption] = useState(0);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (intervalData.length > 0) {
      // Example: Calculate total consumption for the latest interval block
      const latestBlock = intervalData[intervalData.length - 1];
      setConsumption(latestBlock.value);

      // Prepare chart data (e.g., last 24 intervals)
      const last24Blocks = intervalData.slice(-24);
      const formattedData = formatConsumptionData(last24Blocks);
      setChartData(formattedData);
    }
  }, [intervalData]);

  // Render loading indicator
  if (loading) {
    return (
      <div>
        <CircularProgress />
        <Typography variant="subtitle1" mt={2}>
          Loading Energy Consumption...
        </Typography>
      </div>
    );
  }

  // Render error message
  if (error) {
    return (
      <div>
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  // Render the total consumption
  return (
    <div>
      <Typography variant="subtitle1">Total Energy Consumption</Typography>
      <Typography variant="h4">
        {consumption.toLocaleString()} kWh
      </Typography>
      {/* Optional: Add a small line graph here using chartData */}
    </div>
  );
}

export default CurrentEnergyConsumption;
