// src/components/documents/DocumentsPanel.js

import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Download, Delete } from '@mui/icons-material';

function DocumentsPanel({ userId, token }) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, documentId: null, documentTitle: '' });

  const initPath = "documents/users"

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/admin/`+initPath+`/${userId}/documents`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch documents');
      }

      const data = await response.json();
      setDocuments(data.documents);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
      setDocuments([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleDownload = async (documentId, fileName) => {
    try {
      const response = await fetch(`/api/admin/`+initPath+`/${userId}/documents/${documentId}/download`, {
        method: 'GET',
        headers: {
          'x-auth-token': token,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to download document');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading document:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleDeleteClick = (documentId, documentTitle) => {
    setDeleteDialog({ open: true, documentId, documentTitle });
  };

  const handleConfirmDelete = async () => {
    const { documentId, documentTitle } = deleteDialog;
    try {
      const response = await fetch(`/api/admin/`+initPath+`/${userId}/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
          'x-auth-token': token,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete document');
      }

      const data = await response.json();
      setSnackbar({ open: true, message: data.message, severity: 'success' });
      setDeleteDialog({ open: false, documentId: null, documentTitle: '' });
      fetchDocuments(); // Refresh the documents list
    } catch (error) {
      console.error('Error deleting document:', error);
      setSnackbar({ open: true, message: error.message, severity: 'error' });
      setDeleteDialog({ open: false, documentId: null, documentTitle: '' });
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialog({ open: false, documentId: null, documentTitle: '' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box>
      {/*<Typography variant="subtitle1" gutterBottom>
        Documents:
      </Typography>*/}
      {loading ? (
        <CircularProgress />
      ) : documents.length > 0 ? (
        <List>
          {documents.map((document) => (
            <ListItem key={document._id} divider>
              <ListItemText
                primary={document.title}
                secondary={new Date(document.date).toLocaleDateString()}
              />
              <IconButton
                onClick={() => handleDownload(document._id, document.title)}
                title="Download Document"
                color="primary"
              >
                <Download />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteClick(document._id, document.title)}
                title="Delete Document"
                color="error"
              >
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2">No documents available.</Typography>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={handleCancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the document "{deleteDialog.documentTitle}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DocumentsPanel;
