import React from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import { Lock, LockOpen, Edit } from '@mui/icons-material';
import DocumentsPanel from '../../documents/DocumentsPanel';
import UploadDocument from '../../documents/UploadDocument';

function BasicUserView({
  editMode,
  toggleEditMode,
  toggleFullEditMode,
  userFields,
  setUserFields,
  handleGoToUserDashboard,
  saveBasicUserChanges,
}) {
  const handleFieldChange = (field, value) => {
    setUserFields((prev) => ({ ...prev, [field]: value }));
  };

  const managerName = userFields.accountManager?.name;
  const managerEmail = userFields.accountManager?.email;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="h6">Basic Info</Typography>
        <Box>
          <IconButton
            onClick={() => {
              if (editMode) {
                saveBasicUserChanges();
              }
              toggleEditMode();
            }}
            title={editMode ? 'Save Changes' : 'Edit'}
          >
            {editMode ? <LockOpen color="success" /> : <Lock />}
          </IconButton>
          <IconButton
            onClick={toggleFullEditMode}
            title="Edit All Fields"
            sx={{ ml: 1 }}
          >
            <Edit />
          </IconButton>
          <Button
            variant="contained"
            color="info"
            onClick={handleGoToUserDashboard}
            sx={{ ml: 2 }}
          >
            View User Dashboard
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle1">
              <strong>User ID:</strong> {userFields._id}
            </Typography>
          </Box>
          <TextField
            label="Name"
            value={userFields.name || ''}
            variant="outlined"
            onChange={(e) => handleFieldChange('name', e.target.value)}
            fullWidth
            margin="normal"
            disabled={!editMode}
          />
          <TextField
            label="Email"
            value={userFields.email || ''}
            variant="outlined"
            onChange={(e) => handleFieldChange('email', e.target.value)}
            fullWidth
            margin="normal"
            disabled={!editMode}
          />
          <TextField
            label="Role"
            value={userFields.role || ''}
            variant="outlined"
            onChange={(e) => handleFieldChange('role', e.target.value)}
            fullWidth
            margin="normal"
            disabled={!editMode}
          />
          <TextField
            label="City"
            value={userFields.city || ''}
            variant="outlined"
            onChange={(e) => handleFieldChange('city', e.target.value)}
            fullWidth
            margin="normal"
            disabled={!editMode}
          />
          <TextField
            label="School District"
            value={userFields.schoolDistrict || ''}
            variant="outlined"
            onChange={(e) => handleFieldChange('schoolDistrict', e.target.value)}
            fullWidth
            margin="normal"
            disabled={!editMode}
          />

          {userFields.accountManager ? (
            <>
              <TextField
                label="Assigned Manager"
                value={managerName || ''}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
              <TextField
                label="Manager Email"
                value={managerEmail || ''}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
            </>
          ) : (
            <Typography sx={{ mt: 2, fontStyle: 'italic' }}>
              No manager assigned
            </Typography>
          )}
        </Grid>

        {/* Right column: Documents */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Documents:</strong>
          </Typography>
          {/* pass real token if you have it available in props */}
          <DocumentsPanel userId={userFields._id} token="" />
          <UploadDocument userId={userFields._id} token="" refreshDocuments={() => {}} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default BasicUserView;
