// src/pages/Notifications.js
import React, {useEffect} from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import NotificationsPanel from '../components/notifications/NotificationsPanel';


function Notifications() {
  useEffect(() => {
    document.title = 'Notifications';
  }, []);
  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h6">Notifications</Typography>
        <NotificationsPanel />
      </Paper>
    </Grid>
  );
}

export default Notifications;
