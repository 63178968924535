// src/components/layout/AdminSidebar.js
import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';
import {
  Dashboard,
  ExpandLess,
  ExpandMore,
  SupervisorAccount,
  SettingsApplications,
  ExitToApp,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';

function AdminSidebar() {
  const { logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Track which sections are expanded
  const [open, setOpen] = React.useState({});
  // Track whether the entire sidebar is collapsed or expanded
  const [collapsed, setCollapsed] = React.useState(false);

  // Toggle sub-menu expansions
  const handleToggle = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  // Logout
  const onLogout = () => {
    logoutUser();
    navigate('/');
  };

  // Adjust widths as needed
  const drawerWidth = 240;
  const collapsedWidth = 64;

  // Toggle the entire sidebar collapse
  const handleCollapseToggle = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: collapsed ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: collapsed ? collapsedWidth : drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
        },
      }}
    >
      {/* Add a top toolbar for spacing */}
      <Toolbar />
      <List>
        {/* Example Admin top-level item with sub-items */}
        <ListItem button onClick={() => handleToggle('adminDashboard')}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Admin Dashboard" />
              {open.adminDashboard ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>

        {/* Sub-items for admin dashboard */}
        {!collapsed && (
          <Collapse in={open.adminDashboard} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/admin')}
              >
                <ListItemText primary="Overview" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/admin/users')}
              >
                <ListItemText primary="Manage Users" />
              </ListItem>
            </List>
          </Collapse>
        )}

        {/* Another admin item, for example "System Settings" */}
        <ListItem button onClick={() => handleToggle('systemSettings')}>
          <ListItemIcon>
            <SettingsApplications />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="System Settings" />
              {open.systemSettings ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>
        {!collapsed && (
          <Collapse in={open.systemSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/admin/settings/logs')}
              >
                <ListItemText primary="View Logs" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/admin/settings/config')}
              >
                <ListItemText primary="Configuration" />
              </ListItem>
            </List>
          </Collapse>
        )}

        {/* Example link to an "Accounts" page */}
        <ListItem button onClick={() => handleToggle('accounts')}>
          <ListItemIcon>
            <SupervisorAccount />
          </ListItemIcon>
          {!collapsed && (
            <>
              <ListItemText primary="Accounts" />
              {open.accounts ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItem>
        {!collapsed && (
          <Collapse in={open.accounts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/admin/accounts/pending')}
              >
                <ListItemText primary="Pending Accounts" />
              </ListItem>
              <ListItem
                button
                sx={{ pl: 4 }}
                onClick={() => navigate('/admin/accounts/all')}
              >
                <ListItemText primary="All Accounts" />
              </ListItem>
            </List>
          </Collapse>
        )}

        {/* Logout */}
        <ListItem button onClick={onLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Logout" />}
        </ListItem>
      </List>

      {/* Spacer to push collapse button to bottom */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Collapse/Expand Button */}
      <List>
        <ListItem
          button
          onClick={handleCollapseToggle}
          sx={{
            justifyContent: collapsed ? 'center' : 'flex-start',
          }}
        >
          <ListItemIcon>
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </ListItemIcon>
          {!collapsed && <ListItemText primary="Collapse Sidebar" />}
        </ListItem>
      </List>
    </Drawer>
  );
}

export default AdminSidebar;
