// src/context/DataContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  calculateBillingMetrics,
  formatBillingHistory,
} from '../utils/dataFormatting';
import AuthContext from './AuthContext';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { authState } = useContext(AuthContext);

  const [intervalData, setIntervalData] = useState([]);
  const [billingMetrics, setBillingMetrics] = useState({
    totalBilling: 0,
    averageCostPerKwh: 0,
    previousBilling: 0,
  });
  const [billingHistory, setBillingHistory] = useState([]);
  const [metrics, setMetrics] = useState({
    // Placeholder defaults:
    currentProduction: 1500,
    totalSavings: 3200,
    carbonReduction: 250,
    energySources: [
      { source: 'Solar', value: 80 },
      { source: 'Utility', value: 15 },
      { source: 'Wind', value: 5 },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // If auth is loading or user not authenticated, skip
    if (authState.loading) return;
    if (!authState.isAuthenticated) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // 1) GET usage data (and metrics) from server
        const response = await axios.get('/api/usageData');
        const { usageData = [], metrics: serverMetrics = {} } = response.data;

        // 2) Convert usageData’s startDate if needed
        const validatedData = usageData.map((item) => {
          const rawDate = item.startDate?.$date || item.startDate;
          return {
            ...item,
            startDate: rawDate ? new Date(rawDate) : null,
          };
        });

        // 3) Store usageData
        setIntervalData(validatedData);

        // 4) Overwrite placeholder metrics with server metrics
        //    If the server returns partial metrics, consider merging
        setMetrics(serverMetrics);

        // 5) Billing metrics & history
        const newBillingMetrics = calculateBillingMetrics(validatedData);
        setBillingMetrics(newBillingMetrics);

        const history = formatBillingHistory(validatedData);
        setBillingHistory(history);

      } catch (err) {
        console.error('Error fetching usage data/metrics:', err);
        setError('Failed to load data from DB.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authState.loading, authState.isAuthenticated]);

  return (
    <DataContext.Provider
      value={{
        intervalData,
        billingMetrics,
        billingHistory,
        metrics,
        loading,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
